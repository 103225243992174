import columbiaBanner from "../assets/columbiaBanner.jpg";
import columbia1 from "../assets/columbia1.png";
import columbia2 from "../assets/columbia2.png";
import columbia3 from "../assets/columbia3.png";
import columbia4 from "../assets/columbia4.png";
import columbiaNewWF from "../assets/columbiaOldWF.png";
import columbiaOldWF from "../assets/columbiaNewWF.png";
import columbiaLogos from "../assets/columbiaLogos.png";
import columbiaSS1 from "../assets/columbiaSS1.png";
import columbiaSS2 from "../assets/columbiaSS2.png";
import columbiaProto from "../assets/columbiaProto.png";
import columbiaIterations from "../assets/columbiaIterations.png";
import columbiaAfterDesign from "../assets/columbiaAfterDesign.png";
import columbiaBefore from "../assets/columbiaBefore.png";

const columbiaData = {
  general: {
    banner: columbiaBanner,
    nextProject: "/project/theatrSeatr",
    previousProject: "/project/evergreen",
    title: "COLUMBIA CITY FITNESS CENTER",
    subtitle: "UX & UI Redesign",
    description:
      "Columbia City Fitness is a locally-owned powerlifting gym in Seattle. The gym’s web app is outdated and unimpressionable, so I decided to recreate their web app for practice and fun!",
    role: "Sole UX / UI Designer",
    duration: "3 Weeks",
    tools: ["Figma", "Canva"],
    responsibilities: [
      "Low fidelity wireframing",
      "High fidelity prototyping",
      "Conducting usability studies",
      "Accounting for accessibility",
      "Iterating on designs",
    ],
    problem:
      "This web app was inaccessible, flooded with text, and unappealing. It’s navigation was confusing and outdated.",
    goal: "To make the CCFC web app more appealing, accessible, and overall more intuitive to use.",
  },
  photos: [columbia1, columbia2, columbia3, columbia4],
  prototype:
    "https://www.figma.com/proto/RsfNgiT7oCeozOyD0oErjn/Columbia-City-Fitness-Center?node-id=2%3A2&scaling=scale-down&page-id=0%3A1&starting-point-node-id=2%3A2",
  accordion: {
    WIREFRAMING: [
      { title: "WIREFRAMING" },
      {
        text: "I chose to start this process by rebuilding the web app’s current design using low fidelity digital wireframes. I started here because I wanted to look past the outdated UI and focus on whether the app’s functionality and IA also needed reworking before going forward with a redesign.",
      },
      { subtitle: "OUTDATED WIREFRAMES" },
      { image: { src: columbiaOldWF, alt: "" } },
      {
        text: "After looking over the current state of the app, I created new digital wireframes that represent the layout of the redesign. To provide the app with more functionality and a smoother interface, I chose to make some minor adjustments to the AI such as: condensing some of the text into cards, eliminating the services page by combining it with the memberships page (the information was identical on both pages), and reworking the welcome and gallery pages. I also noticed there was not a place that encouraged users to join the gym from the app, so I implemented a “Join” CTA.",
      },
      { subtitle: "UPDATED WIREFRAMES" },
      { image: { src: columbiaNewWF, alt: "" } },
      {
        text: "My design simplifies the browsing flow and includes a gym registration flow. After I finished building the new wireframes, I began working on the UI by giving the branding a little facelift and creating a UI kit to match the overall aesthetic of the gym.",
      },
    ],
    "UI & BRANDING": [
      { title: "UI & BRANDING" },
      { subtitle: "LOGO DESIGN" },
      { image: { src: columbiaLogos, alt: "" } },
      {
        text: "Columbia City Fitness Center’s current logo (above on the left) is not only outdated, but it is also oversized and hard to read. To give the web app a more modern feel, I started with redesigning the logo (above on the right). I wanted to continue to reflect the tight-knit community gym feel that CCFC already portrays while giving it a fresh update. Similar to the typography color of the current logo, I chose to stick with soft black in the new design. However, instead of using it for the text, I chose to make it the background color of both the logo and the overall web app. I also chose to switch out the illustration of the man and woman in the gym’s current logo for a dumbbell to avoid any misrepresentation.",
      },
      { subtitle: "TYPOGRAPHY & COLOR" },
      { image: { src: columbiaSS1, alt: "" } },
      {
        text: "Trying not to stray too far from the gym’s already established identity, I chose to stick with their Roboto Slab font for the content. The serif font expells a classic gym feel while also deems legibility. To avoid overwhelming the user, I chose to pair the Roboto Slab with the sans-serif font, Prompt, for the navigation. I wanted to differentiate the button typography from the content typography to make sure that the navigation options are clear to the user. I also chose to stick with CFCC’s simple red, black, and white color palette to keep the web app accessible.",
      },
      { subtitle: "UI KIT" },
      { image: { src: columbiaSS2, alt: "" } },
      {
        text: "I put together a small UI kit early on to make the design process a bit more efficient. This UI kit gives a clear guide on how to treat each component’s states. While creating the UI kit, I made sure to keep my color palette consistent to avoid confusion and to keep the web app accessible and intuitive.",
      },
    ],
    "TESTING & ITERATIONS": [
      { title: "HIGH FIDELITY PROTOTYPES" },
      { image: { src: columbiaProto, alt: "" } },
      {
        text: "After creating my UI kit, I applied it to my digital wireframes. Lastly, I added all the important features, pages, and interactions. The prototype illustrates the user experience throughout the web app. I organized the screens on my artboard by their states during the prototype phase: “default flow”, “nav menu open”, and “secondary”.",
      },
      { title: "USABILITY TESTS" },
      {
        text: "Once my prototype was finished, I conducted a usability test that consisted of 6 participants over a two day period using Maze. The goal of the usability test was to make sure that my design decisions deemed successful. I was specifically focusing on the success of the web app’s legibility,  usability, and registration flow.",
      },
      { subtitle: "Overview" },
      {
        text: "I presented participants with the following tasks:",
        style: { marginBottom: "8px" },
      },
      {
        ol: [
          "You're new to the gym and interested in checking out if the gym has a free trial period. Navigate to the memberships page, and get the free trials information.",
          "You're interested in learning more about the gym. From the home page, navigate to the About Us page.",
          `You've decided that the Columbia City Fitness Center (Judkins Park location) is the gym for you! Please start the registration flow by tapping "Join".`,
        ],
      },
      {
        text: "Once the participants completed each task, I asked them how intuitive the task felt  using an opinion scale (1= not at all, 10 = very intuitive). ",
      },
      { subtitle: "Findings" },
      {
        text: "The usability test validated most of my design decisions. Participants had no trouble with the site flows. There were no drop offs and the overall usability score was an 87/100. With the average score of a 8.5 out of 10 on the opinion scale, participants felt the first task was intuitive. The second task averaged an 9.5 out of 10, and the third task averaged an 8.7 out of 10.",
      },
      { subtitle: "Further Questioning" },
      {
        text: "To get more insight on the design from the participants, I asked them a couple more follow-up questions:",
        style: { marginBottom: "8px" },
      },
      {
        ul: [
          "Was there anything about the website that you particularly enjoyed?",
          "Was there anything about the website that you found particularly frustrating?",
          "Did you find the text / color palette legible? (Yes or No)",
          "Do you have any additional comments?",
        ],
      },
      {
        text: "If the participants were not interested in answering the follow-up, open answer questions they had the option to comment “N/A”. Many participants opted out of these questions, but a few participants decided to respond with informative insights to consider in my first round of iterations. 100% of the participants felt that the color palette and text was legible. However, two participants mentioned that the text was a little large and they preferred more breathing room. Two other participants mentioned that they had expected to see the free trial information near or alongside the “join” information. Lastly, I had some participants also point out that the gym locations tabs did not particularly stand out to them.",
      },
      { subtitle: "Iterations #1" },
      {
        wrap: [
          {
            wrap: [
              {
                text: "After recieving the feedback from the usability test, I went into my design and made a few updates. To make the design feel less crowded and more breathable, I made some of the content text smaller and lighter weight. On the Join Now page, I both included the gym’s free trial information and, to make the location options stand out a bit more, I added a stroke around the active tab. ",
              },
              {
                text: "In the future I will conduct another usability test to see if my iterations improved the usability score and made for an overall better user experience. ",
              },
            ],
            style: { flex: "3", marginRight: "32px" },
          },
          { image: { src: columbiaIterations, alt: "" }, style: { flex: "1" } },
        ],
        style: { display: "flex" },
      },
    ],
    "FINAL PRODUCT": [
      { title: "COLUMBIA CITY FITNESS CENTER" },
      { subtitle: "CCFC Before Redesign" },
      { image: { src: columbiaBefore, alt: "" } },
      { subtitle: "CCFC After Redesign" },
      { image: { src: columbiaAfterDesign, alt: "" } },
      {
        text: "The CCFC web app was redesigned to provide users with a more modern feel, simple navigation, and the ability to register to the gym from the web. The user starts on the home page, where they are greeted with several CTA’s. The “join now” button brings them to a form where they are able to register either to the Columbia City or Judkins Park location. If the user decides to scroll down they will see a photo gallery and information regarding the gym spaces and the equipment that they provide. Below the photo gallery there are three CTA’s: personal training, classes, and powerlifting. These CTA’s bring the user to their respective pages. The user is also able to navigate throughout the web app using the sticky hamburger menu at the top of each screen.  Once the hamburger menu is open, a navigation pop out will appear on the right side of the screen. The navigation pop out consists of eight CTA’s: Join, about us, memberships, services, event photos, contact us, Andrew “Bull” Stewart, and blog. From the Memberships page, the user is able to tap buttons that open a drop down with information related to the specific memberships. Similar to the memberships page, the services page also provides the user with buttons that open into drop downs and provide further information regarding the gym’s services.  Throughout the design, call-to-actions’s encourage the user to browse through the content and register to the gym.",
      },
      {
        text: "To see the full application:",
        style: { alignSelf: "center", marginBottom: "32px" },
      },
      {
        buttonLink: {
          text: "GO TO PROTOTYPE",
          link: "https://www.figma.com/proto/RsfNgiT7oCeozOyD0oErjn/Columbia-City-Fitness-Center?node-id=2%3A2&scaling=scale-down&page-id=0%3A1&starting-point-node-id=2%3A2",
        },
        style: { alignSelf: "center", marginBottom: "64px" },
      },
    ],
  },
  finalText:
    "I decided to redesign the Columbia City Fitness Center web app to work on my UI / UX skills and to focus on accessiblility practices. Although I did not work directly with the gym during this design process, I wanted to create a project for an existing business to see how it felt to design under some realistic, small business constraints such as limited staffing and a small budget. Designing for a community gym helped me better understand the industry and the inner workings of a casual registration flow.",
};

export default columbiaData;
