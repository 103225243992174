import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { breakpoints, debounce, fadeAnim } from "../constants";

const float = keyframes`
    0% {
        transform: translate(7px, 0px);
    }
    50% {
        transform: translate(7px, 8px);
    }
    100% {
        transform: translate(7px, 0px);
    }
`;
const FadeWrap = styled.div`
  position: absolute;
  right: 50%;
  bottom: 24px;
  opacity: 0;
  ${fadeAnim};
  animation-delay: 3s;
`;
const Wrapper = styled.svg`
  animation: 1s ${float} ease-in-out infinite;
  height: 36px;
  width: 36px;

  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;
const RoundWrap = styled.div`
  opacity: ${(props) => (props.shouldDisappear ? "0" : "1")};
  transition: opacity 0.2s ease;
  border-radius: 22px;
  height: 60px;
  border: 4px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;
function ScrollDown() {
  const [scroll, setScroll] = useState(window.scrollY);
  const [gone, setGone] = useState(false);
  const shouldDisappear = scroll > 60;

  const handleScroll = debounce(() => {
    setScroll(window.scrollY);
  }, 100);

  useEffect(() => {
    if (shouldDisappear) {
      setGone(true);
      window.removeEventListener("remove", handleScroll);
    }
  }, [setGone, shouldDisappear, handleScroll]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <FadeWrap>
      <RoundWrap shouldDisappear={scroll > 60 || gone}>
        <Wrapper
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          ariaHidden="true"
          viewBox="0 0 24 24"
        >
          <line
            x1="6.91901"
            y1="1.32905"
            x2="6.91901"
            y2="14.6195"
            stroke="black"
            strokeWidth="2.6581"
            strokeLinecap="round"
          />
          <path
            d="M2.09729 11.5059L6.91899 14.6195"
            stroke="black"
            strokeWidth="2.6581"
            strokeLinecap="round"
          />
          <line
            x1="7.33349"
            y1="14.3229"
            x2="11.731"
            y2="11.547"
            stroke="black"
            strokeWidth="2.6581"
            strokeLinecap="round"
          />
        </Wrapper>
      </RoundWrap>
    </FadeWrap>
  );
}

export default ScrollDown;
