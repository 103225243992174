import withAccordion from "../layout/project/Accordion";
import React from "react";
import styled from "styled-components";
import { breakpoints, colors, fonts, Heading, Text } from "../constants";
import { StyledCoolBox } from "../layout/project/Pictures";
import CoolBtn from "./CoolBtn";

const ContentWrap = styled.div`
  padding: 64px 32px 32px;
  display: flex;
  flex-direction: column;
  background-color: ${colors.bg};
`;
const ContentTitle = styled.h3`
  font-family: ${fonts.secondary};
  font-weight: 700;
  align-self: center;
  font-size: 1.5rem;
  margin-bottom: 64px;
`;
const ContentSubtitle = styled(Heading)`
  margin-bottom: 16px;
  font-size: 1.1rem;
  font-weight: 700;
`;
const ContentText = styled(Text)`
  margin-bottom: 32px;
`;
const ContentList = styled.ul`
  list-style: none;
  font-family: ${fonts.secondary};
  margin-bottom: 48px;
  padding-left: 8px;
  & > li {
    margin-bottom: 8px;
    font-size: 1rem;
  }
`;
const ContentListItem = styled.li`
  display: flex;
  align-items: center;
  &:before {
    content: "";
    width: 2px;
    height: 2px;
    background-color: black;
    border-radius: 50%;
    margin-right: 8px;
  }
`;
const ContentOrderedList = styled.ol`
  font-family: ${fonts.secondary};
  margin-bottom: 64px;
  & > li {
    margin-bottom: 8px;
    font-size: 18px;
  }
`;
const ContentImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 64px;
`;
const ContentContainer = styled.div`
  width: 100%;
  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
  }
`;
const CloseBtn = styled.button`
  font-family: ${fonts.secondaryBold};
  font-size: 1.4rem;
  font-weight: 600;
  border: none;
  background-color: ${colors.bg};
  color: ${colors.primaryHighlight};
  display: block;
  margin: 0px auto 24px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &:after {
    content: "";
    width: 100%;
    height: 2px;

    box-shadow: 0 2px 0 0 ${colors.primaryHighlight};
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  &:hover {
    &:after {
      opacity: 1;
    }
  }
`;
const StyledCoolBtn = styled(CoolBtn)`
  font-size: 1.4rem;
`;
const contentGenerator = (content) => {
  const result = content.map((node, idx) => {
    const jsx = Object.entries(node).map(([key, val], idx) => {
      switch (key) {
        case "title":
          return (
            <ContentTitle
              style={node.style && node.style}
              key={val + idx + "title"}
            >
              {val}
            </ContentTitle>
          );
        case "subtitle":
          return (
            <ContentSubtitle
              style={node.style && node.style}
              key={val + idx + "subtitle"}
            >
              {val}
            </ContentSubtitle>
          );
        case "text":
          return (
            <ContentText
              style={node.style && node.style}
              key={val + idx + "text"}
            >
              {val}
            </ContentText>
          );
        case "ul":
          return (
            <ContentList
              style={node.style && node.style}
              key={key + "ul" + idx + val.length}
            >
              {val.map((item, idx) => (
                <ContentListItem key={item + idx}>{item}</ContentListItem>
              ))}
            </ContentList>
          );
        case "ol":
          return (
            <ContentOrderedList
              style={node.style && node.style}
              key={key + "ol" + idx + val.length}
            >
              {val.map((item, idx) => (
                <li key={item + idx}>{item}</li>
              ))}
            </ContentOrderedList>
          );
        case "image":
          return (
            <ContentImage
              style={node.style && node.style}
              key={val.src + idx + "img"}
              src={val.src}
              alt={val.alt}
            />
          );
        case "buttonLink":
          return (
            <a
              href={val.link}
              style={node.style && node.style}
              target="_blank"
              rel="noreferrer"
              key={val.link + idx}
            >
              <StyledCoolBtn border="black">{val.text}</StyledCoolBtn>
            </a>
          );

        case "wrap": {
          const resultTest = contentGenerator(val);
          return (
            <ContentContainer key={key + idx + "wrap"} style={node.style}>
              {resultTest}
            </ContentContainer>
          );
        }
        default:
          return null;
      }
    });
    return jsx;
  });
  return result;
};
const AccordionContent = ({ content, handleClose }) => {
  return (
    <ContentWrap>
      {contentGenerator(content)}
      <CloseBtn onClick={handleClose}>Click Here to Close</CloseBtn>
    </ContentWrap>
  );
};

export default withAccordion(AccordionContent);
