import React from "react";
import Page from "../components/Page";
import styled, { keyframes } from "styled-components";
import Exclamation from "../components/Exclamation";
import FlexContainer from "../components/FlexContainer";
import { breakpoints, colors, fadeAnim, fonts, Highlight } from "../constants";
import alissaToon from "../assets/alissa2.svg";
import { animated } from "react-spring";
import mushtache from "../assets/mushstache.png";
import AlissaToon from "../components/AlissaToon";
import HomeImg from "../assets/homeImg.png";
import BigSparkles from "../components/BigSparkles";
import { useWindowWidth } from "@react-hook/window-size";
import ScrollDown from "../components/ScrollDown";
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

`;
const StyledPage = styled(Page)`
  display: flex;
  align-items: center;
  position: relative;
  isolation: isolate;
  margin-bottom: 200px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  /* @media (max-width: 900px) {
    padding-top: 60px;
    padding-bottom: 80px;
    height: auto;
  }
  @media (max-width: ${breakpoints.xs}) {
    padding-bottom: 20px;
    height: initial;
  } */
`;

const BarWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.primaryHighlight};
  height: 320px;
  position: relative;
  & > * {
    &:nth-child(1) {
      opacity: 0;
      animation: 0.8s ${fadeIn} ease-in forwards;
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      opacity: 0;
      animation: 0.8s ${fadeIn} ease-in forwards;
      animation-delay: 0.7s;
    }
    &:nth-child(3) {
      opacity: 0;
      animation: 0.8s ${fadeIn} ease-in forwards;
      animation-delay: 1.2s;
    }
    &:nth-child(4) {
      opacity: 0;
      animation: 0.8s ${fadeIn} ease-in forwards;
      animation-delay: 1.7s;
    }
  }
  @media (max-width: ${breakpoints.lg}) {
    height: 256px;
  }
  @media (max-width: 1000px) {
    height: 200px;
  }
  @media (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    height: 240px;
    padding: 20px 0px;
  }
`;
const BarImg = styled.img`
  height: 120%;
  width: auto;
  transform: translateY(-40px);
  @media (max-width: ${breakpoints.lg}) {
    height: 100%;
  }
  @media (max-width: ${breakpoints.sm}) {
    height: 140px;
    transform: translateY(-10px);
  }
  /* margin-top: -40px; */
`;
const Circle = styled.div`
  width: auto;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  position: absolute;
  left: -57vh;
  background-color: ${colors.secondaryHighlight};
  z-index: -1;
`;
const RotatedText = styled.p`
  font-size: 1.8rem;
  font-family: ${fonts.secondary};
  color: ${colors.secondary};
  transform: rotate(${(props) => (props.reverse ? "-" : "")}90deg);
  @media (max-width: ${breakpoints.lg}) {
    font-size: 1.5rem;
  }
  @media (max-width: 1000px) {
    font-size: 1.2rem;
  }
  @media (max-width: ${breakpoints.sm}) {
    transform: none;
  }
`;
const Subtitle = styled.div`
  font-family: ${fonts.secondary};
  font-size: 1.3rem;
  position: absolute;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: ${breakpoints.md}) {
    font-size: 1.2rem;
  }
  @media (max-width: ${breakpoints.sm}) {
    font-size: 1rem;
    width: 100%;
    text-align: center;
  }
  @media (max-width: ${breakpoints.xs}) {
    font-size: 0.8rem;
  }
  /* border: 2px solid red; */
  /* text-align: center; */
  /* width: 100%; */
`;
const Home = () => {
  return (
    <StyledPage>
      <Circle />
      <BarWrap>
        <RotatedText reverse>Welcome!</RotatedText>
        <BarImg src={HomeImg} alt="" />
        <RotatedText>Alissa Monterusso</RotatedText>
        <Subtitle>Thanks for coming to check things out!</Subtitle>
      </BarWrap>
      <ScrollDown />
    </StyledPage>
  );
};

export default React.memo(Home);
