import React, { useEffect } from "react";
import styled from "styled-components";
import {
  InstaIcon,
  BehanceIcon,
  LinkedinIcon,
  DribbleIcon,
} from "../assets/Icons.js";
import { CoolBox } from "../components/CoolBox";
import { breakpoints, colors, fonts, PageTitle, Text } from "../constants";
import dot from "../assets/dot.svg";
import { useWindowWidth } from "@react-hook/window-size";
import resume from "../RESUME.pdf";
import CoolBtn from "../components/CoolBtn.js";

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primaryHighlight};
  padding: 60px 0px;
  position: relative;
`;

const Title = styled(PageTitle)`
  /* -webkit-text-stroke: 2px black; */
  color: ${colors.secondary};
  padding-bottom: 16px;
  font-family: ${fonts.primary};
  font-size: 3.5rem;
  @media (max-width: ${breakpoints.sm}) {
    font-size: 3rem;
  }
`;
const Email = styled.p`
  font-family: ${fonts.secondary};
  color: ${colors.secondary};
  font-size: 1.4rem;
  font-weight: 600;
  transform: translateY(4px);
  @media (max-width: ${breakpoints.sm}) {
    font-size: 1.1rem;
    transform: translateY(0px);
  }
`;
const ResumeText = styled(Email)`
  padding-bottom: 0px;
  transform: none;
  color: inherit;
`;
const IconWrapper = styled.div`
  display: flex;
  margin-top: 48px;
`;
const IconLink = styled.a`
  background-color: ${colors.primaryHighlight};
  border-radius: 50%;
  margin: 0px 4px;
  cursor: pointer;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;

  & path {
    fill: ${colors.secondary};
    transition: fill 0.2s ease;
  }

  &:hover {
    background-color: ${colors.secondary};
    box-shadow: 0px 0px 0px 2px ${colors.secondary};
    & path {
      fill: ${colors.primaryHighlight};
    }
  }
  &:first-child {
    margin-left: 0px;
    &:hover {
      background-color: ${colors.secondary};
      box-shadow: 0px 0px 0px 1px ${colors.secondary};
      & path {
        fill: ${colors.primaryHighlight};
      }
    }
  }
  &:last-child {
    margin-right: 0px;
  }
`;
const CoolEmailBox = styled(CoolBox)`
  margin: 0px auto;
  display: grid;
  place-items: center;
`;
const CoolResumeBox = styled(CoolEmailBox)`
  cursor: pointer;
`;
const BrokenDot = styled.img`
  width: 1.1vw;
  height: 1.1vw;
  position: absolute;
  top: -1.3vw;
`;
const EmailLink = styled.a`
  text-decoration: none;
  margin-bottom: 36px;
  transition: box-shadow 0.2s ease;
  &:hover {
    box-shadow: 0px 2px 0px 0px ${colors.secondary};
  }
`;

const Contact = ({ contactRef }) => {
  return (
    <Wrapper ref={contactRef} id="contact">
      <Title>HOLLER AT ME!</Title>
      <EmailLink href="mailto: alissa.monterusso@gmail.com">
        <Email>Alissa.Monterusso@gmail.com</Email>
      </EmailLink>
      <a href={resume} rel="noreferrer" target="_blank">
        <CoolBtn>
          <ResumeText>My Resume</ResumeText>
        </CoolBtn>
      </a>
      <IconWrapper>
        <IconLink href="https://www.instagram.com/balissyussy/" target="_blank">
          <InstaIcon />
        </IconLink>
        <IconLink href="https://www.behance.net/alissamonteru" target="_blank">
          <BehanceIcon />
        </IconLink>
        <IconLink href="https://dribbble.com/Alimonte28" target="_blank">
          <DribbleIcon />
        </IconLink>
        <IconLink href="https://www.linkedin.com/in/alimonte" target="_blank">
          <LinkedinIcon />
        </IconLink>
      </IconWrapper>
      {/* <BrokenDot src={dot} alt="" /> */}
    </Wrapper>
  );
};

export default React.memo(Contact);
