import React from "react";
import { Link } from "react-router-dom";
import { breakpoints, colors, fonts } from "../constants";
import { CoolBox } from "./CoolBox";
import evergreenScreen from "../assets/evergreenScreen.png";
import swaScreen from "../assets/swaScreen.png";
import swaLink from "../assets/swaLink.png";
import theatrSeatrScreen from "../assets/theatrSeatrScreen.png";
import theatrSeatrLink from "../assets/theatrSeatrLink.png";
import evergreenLink from "../assets/evergreenLink.jpg";
import columbiaLink from "../assets/columbiaLink.png";
import columbiaScreen from "../assets/ccfScreen.png";
import styled from "styled-components";
import { useWindowWidth } from "@react-hook/window-size";
import CoolProject from "./CoolProject";
const Carousel = styled.div`
  display: flex;
  align-items: center;
  height: 400px;
  width: 100%;
  overflow: auto;
  position: relative;
  gap: 36px;
  padding: 0px 8px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease;
`;
const Cartoon = styled.img`
  width: ${(props) => props.width};
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s ease;
  opacity: 1;
`;
const ImageWrap = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  font-family: ${fonts.primary};
  font-weight: 600;
  font-size: 1.6rem;
  text-align: center;
  transition: color 0.2s ease;
  &:hover {
    color: ${colors.primaryHighlight};
  }
`;

const LinkLabel = styled.p`
  padding-top: 16px;
  font-size: 1.8rem;
  @media (max-width: ${breakpoints.sm}) {
    font-size: 1.5rem;
    padding-top: 8px;
  }
`;
const BoxWrap = styled.div`
  &:hover ${Cartoon} {
    opacity: 0;
  }
  &:hover ${Image} {
    opacity: 1;
  }
`;

const ProjectLinks = () => {
  return (
    <>
      <Carousel>
        <StyledLink to="/project/swa">
          <CoolProject
            img={swaScreen}
            hoverImg={swaLink}
            imgStyle={{ height: "200px", width: "auto" }}
            title="Stronger With Adrienne"
          />
        </StyledLink>
        <StyledLink to="/project/theatrSeatr">
          <CoolProject
            img={theatrSeatrScreen}
            hoverImg={theatrSeatrLink}
            imgStyle={{ height: "200px", width: "auto" }}
            title="Theatr Seatr"
          />
        </StyledLink>
        <StyledLink to="/project/evergreen">
          <CoolProject
            img={evergreenScreen}
            hoverImg={evergreenLink}
            imgStyle={{ height: "180px", width: "auto", marginTop: "20px" }}
            title={"Evergreen WRC"}
          />
        </StyledLink>
        <StyledLink to="/project/columbia">
          <CoolProject
            img={columbiaScreen}
            imgStyle={{ height: "200px" }}
            hoverImg={columbiaLink}
            title="Columbia City Fitness"
          />
        </StyledLink>
      </Carousel>
    </>
  );
};

export default ProjectLinks;
