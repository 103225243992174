import styled, { css, keyframes } from "styled-components";

export const linkTags = {
  work: "work",
  about: "about",
  contact: "contact",
  home: "home",
};
export const projectLinkTags = {
  description: "description",
};

export const colors = {
  bg: "#FFF",
  primary: "#FFF3E8",
  primaryHighlight: "#50532E",
  secondary: "#EEE2D1",
  secondaryHighlight: "#FFDABF",
  text: "black",
};
export const fonts = {
  primary: "Marsh, sans-serif",
  secondary: "Montserrat, sans-serif",
  secondaryBold: "MontserratBold, sans-serif",
};

export const linePosition = {
  side: "14vw",
  sideSmall: "6vw",
  bottom: "120px",
};
export const breakpoints = {
  xs: "600px",
  sm: "900px",
  md: "1200px",
  lg: "1400px",
};
export const PageTitle = styled.h2`
  font-size: 3rem;
  font-family: ${fonts.secondary};
  @media (max-width: 900px) {
    text-align: center;
    font-size: 2rem;
  }
`;
export const Heading = styled.h3`
  font-size: 1.3rem;
  font-family: ${fonts.secondaryBold};
  font-weight: bold;
  @media (max-width: 1400px) {
    font-size: 1.3rem;
  }
`;
export const BigText = styled.p`
  font-size: 1.25rem;
  font-family: ${fonts.secondary};
  @media (max-width: ${breakpoints.sm}) {
    font-size: 1.1rem;
  }
`;
export const Text = styled.p`
  font-size: 1rem;
  font-family: ${fonts.secondary};
  line-height: 1.5;

  ${(props) =>
    props.isAboutRight &&
    `
    display: none;
    @media (max-width: 1400px){
      display: block;
    }
    @media (max-width: 900px) {
      display: none;
    }
  `}
  ${(props) =>
    props.isAboutLeft &&
    `
    @media(max-width: 1400px) {
      display: none;
    }
    @media (max-width: 900px) {
      display: block
    }
  `}
`;
export const Highlight = styled.span`
  color: ${colors.primaryHighlight};
`;
export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}
export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  `;
export const fadeAnim = css`
  animation: ${fadeIn} 1.6s 0.2s ease forwards;
`;
