import React, { useCallback, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { fonts } from "../constants";
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

`;
const Wrapper = styled.nav`
  opacity: 0;
  animation: 0.8s ${fadeIn} ease-in forwards;
  animation-delay: 0.5s;
  position: fixed;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  height: 60px;
  padding: 0px 12%;
  width: 100%;
  transition: background-color 0.2s ease;
  background-color: ${(props) => (props.bg ? "white" : "none")};
`;
const Logo = styled.p`
  font-family: ${fonts.secondary};
  font-size: 1.6rem;
  cursor: pointer;
`;
const NavLinks = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  gap: 40px;
`;
const Anchor = styled.li`
  font-family: ${fonts.secondary};
  height: 100%;
  display: grid;
  place-items: center;
  font-size: 1.2rem;
  & > a {
    text-decoration: none;
    color: black;
    cursor: pointer;
  }
`;

const Navbar = ({ homeRef, workRef, aboutRef, contactRef }) => {
  const [showBg, setShowBg] = useState(false);

  const handleScroll = useCallback(() => {
    setShowBg(true);
  }, []);
  useEffect(() => {
    if (showBg) {
      window.removeEventListener("scroll", handleScroll);
    } else {
      window.addEventListener("scroll", handleScroll);
    }
  }, [showBg]);

  const handleNav = (id) => {
    if (id === "home") {
      window.scrollTo(0, 0);
    }
    if (id === "work") {
      window.scrollTo({
        top: window.pageYOffset + workRef.current.getBoundingClientRect().top,
      });
    }
    if (id === "about") {
      window.scrollTo({
        top: window.pageYOffset + aboutRef.current.getBoundingClientRect().top,
      });
    }
    if (id === "contact") {
      window.scrollTo({
        top:
          window.pageYOffset + contactRef.current.getBoundingClientRect().top,
      });
    }
  };

  return (
    <Wrapper bg={showBg}>
      <Logo
        onClick={(e) => {
          e.preventDefault();
          handleNav("home");
        }}
      >
        am.
      </Logo>
      <NavLinks>
        <Anchor>
          <a
            onClick={(e) => {
              e.preventDefault();
              handleNav("work");
            }}
          >
            work
          </a>
        </Anchor>
        <Anchor>
          <a
            onClick={(e) => {
              e.preventDefault();
              handleNav("about");
            }}
          >
            about
          </a>
        </Anchor>
        <Anchor>
          <a
            onClick={(e) => {
              e.preventDefault();
              handleNav("contact");
            }}
          >
            contact
          </a>
        </Anchor>
      </NavLinks>
    </Wrapper>
  );
};

export default Navbar;
