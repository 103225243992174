import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  flex-wrap: ${(props) => props.wrap};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
`;

const FlexContainer = ({
  direction = "row",
  wrap = "nowrap",
  justifyContent = "flex-start",
  alignItems = "flex-start",
  className,
  children,
  element,
  z,
  animRef,
}) => {
  // const style = {
  //   display: "flex",
  //   "--flexDirection": direction,
  //   "--wrap": wrap,
  //   "--justifyContent": justifyContent,
  //   "--alignItems": alignItems,
  // };
  return (
    <Wrapper
      ref={animRef}
      direction={direction}
      wrap={wrap}
      justifyContent={justifyContent}
      alignItems={alignItems}
      as={element}
      className={className}
    >
      {children}
    </Wrapper>
  );
};

export default FlexContainer;
