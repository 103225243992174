import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { colors, fonts, Text } from "../../constants";
import { ChevronDownIcon } from "../../assets/Icons";
import star from "../../assets/star.svg";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";

const ChildWrapper = styled.li`
  width: 100%;
  overflow: hidden;
  border: 2px solid
    ${(props) => (props.isActive ? colors.secondaryHighlight : "black")};
  border-top: none;
  &:nth-child(1) {
    border: 2px solid
      ${(props) => (props.isActive ? colors.secondaryHighlight : "black")};
    span {
      transform: translateX(6px);
    }
  }
`;
const Number = styled.span`
  font-family: ${fonts.primary};
  font-size: 2.2rem;
  margin-right: ${(props) => (props.order === 1 ? "15px" : "20px")};
  padding-left: 12px;
  width: 48px;
  color: black;
  transition: color 0.2s ease;
  font-weight: bolder;
  letter-spacing: 2px;
`;
const Title = styled.h3`
  font-family: ${fonts.secondary};
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  transition: color 0.2s ease;
  color: black;
  text-transform: capitalize;
`;
const StyledChevron = styled(ChevronDownIcon)`
  width: 30px;
  height: 30px;
  color: black;
  transform: ${(props) => (props.isActive ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.4s ease, color 0.2s ease;
`;
const Content = styled(animated.article)`
  /* max-height: 0px; */
  position: relative;
  overflow: hidden;
  /* opacity: ${(props) => (props.isActive ? "1" : "0")};
  transition: max-height 0.4s ${(props) => (props.isActive ? "0s" : "0.2s")}
      ease,
    opacity ${(props) => (props.isActive ? "0.6s" : "0.2s")}
      ${(props) => (props.isActive ? "0.4s" : "0s")} ease;
  max-height: ${(props) => props.isActive && "12000px"};
  transition-duration: ${(props) => props.isActive && "1.1s"}; */
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isActive ? colors.secondaryHighlight : colors.bg};
  transition: background-color 0.4s ease;

  &:hover {
    background-color: ${colors.secondaryHighlight};
    /* & ${Title} {
      color: ${(props) => (props.isActive ? "black" : colors.primaryHighlight)};
    }
    & ${StyledChevron} {
      color: ${(props) => (props.isActive ? "black" : colors.primaryHighlight)};
    }
    & ${Number} {
      color: ${(props) => (props.isActive ? "black" : colors.primaryHighlight)};
    } */
  }
`;

const Star = styled.img`
  width: 22px;
  height: 22px;
  transform: translateY(4px);
  display: inline;
  margin-left: 4px;
`;

const withAccordion = (Component) => (props) => {
  const { content, order, title, ...rest } = props;
  const defaultHeight = 0;

  const [open, toggle] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  const [ref, { height }] = useMeasure();

  const expand = useSpring({
    config: { mass: 1, tension: 170, friction: 26 },
    height: open ? `${contentHeight}px` : `${defaultHeight}px`,
  });

  const handleContentHeight = useCallback(
    () => setContentHeight(height),
    [height]
  );
  console.log(open);
  useEffect(() => {
    setContentHeight(height);

    window.addEventListener("resize", handleContentHeight);

    return window.removeEventListener("resize", handleContentHeight);
  }, [height]);

  return (
    <ChildWrapper isActive={open}>
      <Header onClick={() => toggle(!open)} isActive={open}>
        <Title isActive={open}>
          <Number order={order} isActive={open}>
            {order}.
          </Number>{" "}
          <span style={{ fontWeight: 400, fontSize: "1.4rem" }}>
            {title}
            {(title === "FINAL DESIGN" || title === "FINAL PRODUCT") && (
              <Star src={star} alt="" />
            )}
          </span>
        </Title>
        <StyledChevron isActive={open} />
      </Header>
      <Content style={expand}>
        <div ref={ref}>
          <Component
            {...rest}
            handleClose={() => toggle((prevState) => !prevState)}
            content={content}
          />
        </div>
      </Content>
    </ChildWrapper>
  );
};

export default withAccordion;
