import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animated } from "react-spring";
import styled, { keyframes } from "styled-components";
import upFinger from "../assets/upFinger.png";
import { breakpoints, colors, debounce, fonts } from "../constants";
import useBoop from "../hooks/useBoop";

const Booper = styled(animated.div)`
  position: fixed;
  bottom: 48px;
  right: 48px;
  cursor: pointer;
  transition: opacity 0.4s ease;
  opacity: ${(props) => (props.$isVisible ? "1" : "0")};
  @media (max-width: ${breakpoints.md}) {
    bottom: 24px;
    right: 24px;
  }
`;

const Image = styled(animated.img)`
  display: block;
  cursor: pointer;
  width: 50px;
  height: auto;
  @media (max-width: ${breakpoints.md}) {
    width: 36px;
  } ;
`;
const Label = styled.p`
  font-family: ${fonts.primary};
  font-size: 1.4rem;
  font-weight: 600;
  transition: color 0.2s ease;
  text-align: center;
  margin-top: 2px;
  color: black;
  /* ${Booper}:hover & {
    color: black;
  } */
  @media (max-width: ${breakpoints.md}) {
    font-size: 1.2rem;
  } ;
`;

const BackToTop = () => {
  const [style, trigger] = useBoop({
    x: 0,
    y: -12,
    rotation: 0,
    scale: 1.1,
    springConfig: { tension: 400, friction: 10 },
  });

  const [scroll, setScroll] = useState(window.scrollY);

  const handleScroll = debounce(() => {
    setScroll(window.scrollY);
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  return (
    <Booper
      $isVisible={scroll > 300 ? true : false}
      onClick={() => scrollToTop()}
      onMouseEnter={trigger}
    >
      <Image style={style} src={upFinger} alt="" />
    </Booper>
  );
};

export default BackToTop;
