import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Page from "../../components/Page";
import FlexContainer from "../../components/FlexContainer";
import { CoolBox } from "../../components/CoolBox";
import {
  colors,
  fadeAnim,
  fadeIn,
  fonts,
  Heading,
  Text,
} from "../../constants";
import AccordionChild from "./Accordion";
import { breakpoints } from "../../constants";
import { useInView } from "react-intersection-observer";
import AccordionContent from "../../components/AccordionContent";
import CoolBtn from "../../components/CoolBtn";
import { Bubble } from "../About";
const StyledPage = styled(Page)`
  height: initial;
  padding-bottom: 200px;
  max-width: 1440px;
  margin: 0px auto;
  @media (max-width: ${breakpoints.xs}) {
    padding: 0px 0px 100px;
  }
`;
const ImageWrap = styled(FlexContainer)`
  animation: 0.8s ${(props) => props.inView && fadeIn} ease-in forwards;
  animation-delay: 0.2s;
  opacity: 0;
  padding-bottom: 40px;
  position: relative;
  & > img {
    width: ${(props) => (props.fullScreen ? "100%" : "20%")};
    height: auto;
    padding: 0px 24px 24px;
    ${(props) =>
      !props.fullScreen &&
      `
    @media (max-width: ${breakpoints.md}) {
      width: 25%;
    }
    @media (max-width: ${breakpoints.sm}) {
      width: 50%;
    }
    @media (max-width: ${breakpoints.xs}) {
      width: 75%;
      margin: 0px auto;
    }`}
  }
`;
const StyledFlexContainer = styled(FlexContainer)`
  margin: 0px auto;
  animation: 0.8s ${(props) => props.inView && fadeIn} ease-in forwards;
  animation-delay: 0.2s;
  opacity: 0;
`;
export const StyledCoolBox = styled(CoolBox)`
  display: grid;
  place-items: center;
  font-size: 1.2rem;
  font-family: ${fonts.secondary};
  font-weight: 600;
  color: ${colors.bg};
  cursor: pointer;
`;
const AccordionWrap = styled(FlexContainer)`
  width: 90%;
  margin: 48px auto 0px;
  animation: 0.8s ${(props) => props.inView && fadeIn} ease-in forwards;
  animation-delay: 0.2s;
  opacity: 0;
  position: relative;
  @media (max-width: ${breakpoints.xs}) {
    width: 100%;
    & > h3,
    & > p {
      padding: 0px 24px;
    }
  }
`;
const Accordion = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 64px;
  list-style: none;
  padding-left: 0px;
  @media (max-width: ${breakpoints.sm}) {
    width: 100%;
  }
`;
const FinalHeading = styled(Heading)`
  font-family: ${fonts.primary};
  color: black;
  align-self: flex-start;
  margin-bottom: 8px;
  letter-spacing: 1px;
`;
const ProtoText = styled(Text)`
  margin-bottom: 32px;
`;
const Observer = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
`;
const StyledCoolBtn = styled(CoolBtn)`
  font-size: 1.4rem;
`;

const Pictures = ({ data }) => {
  const [pictureRef, pictureInView] = useInView({ triggerOnce: true });
  const [accordionRef, accordionInView] = useInView({ triggerOnce: true });
  const [delayOver, setDelayOver] = useState(false);

  useEffect(() => {
    const delayObserver = () => {
      setDelayOver(true);
    };
    setTimeout(delayObserver, 500);
    return () => {
      clearTimeout(delayObserver);
    };
  }, []);
  return (
    <StyledPage delayLines={true}>
      <ImageWrap
        fullScreen={data.photos.length === 1}
        wrap="wrap"
        justifyContent="center"
        inView={pictureInView}
      >
        <Bubble
          height="400px"
          width="400px"
          position="bottom: -120px; right: -120px;"
        />
        {delayOver && <Observer style={{ top: "60px" }} ref={pictureRef} />}
        {data.photos.map((photo, idx) => (
          <img key={idx + photo} src={photo} alt="" />
        ))}
      </ImageWrap>
      <StyledFlexContainer
        justifyContent="center"
        direction="column"
        alignItems="center"
        inView={accordionInView}
      >
        <ProtoText>To see the full application:</ProtoText>
        {data.general?.live ? (
          <a href={data.general.live} target="_blank">
            <StyledCoolBtn border={"black"}>GO TO WEBSITE</StyledCoolBtn>
          </a>
        ) : (
          <a href={data.prototype} target="_blank">
            <StyledCoolBtn border={"black"}>GO TO PROTOTYPE</StyledCoolBtn>
          </a>
        )}
      </StyledFlexContainer>
      <AccordionWrap
        inView={accordionInView}
        alignItems="center"
        direction="column"
      >
        <Bubble
          height="600px"
          width="600px"
          position={"bottom: -300px; left: -400px;"}
        />
        {delayOver && <Observer ref={accordionRef} />}
        <Accordion>
          {Object.entries(data.accordion).map(([key, val], idx) => {
            const order = idx + 1;
            return (
              <AccordionContent
                title={key}
                content={val}
                order={order}
                key={idx + key}
              />
            );
          })}
        </Accordion>
        <FinalHeading>AND SO...</FinalHeading>
        <Text padding="0px">{data.finalText}</Text>
      </AccordionWrap>
    </StyledPage>
  );
};

export default Pictures;
