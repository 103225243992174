import React, { useEffect, useState } from "react";
import { animated } from "react-spring";
import styled, { css, keyframes } from "styled-components";
import useBoop from "../hooks/useBoop";
import dot from "../assets/dot.svg";
import blankDot from "../assets/blankDot.svg";
import { breakpoints } from "../constants";

const Positioner = styled.div`
  position: relative;
`;
const Wrapper = styled(animated.span)`
  cursor: ${(props) => (props.$fallen ? "initial" : "pointer")};
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
`;
const fallAnim = keyframes`
  0% {
   transform: translateY(0px);
  }
  100% {
    transform: translateY(2000px);
    opacity: 0;
  }
`;
const Dot = styled.img`
  width: ${(props) => (props.blankDot ? "1.2vw" : "1.1vw")};
  height: ${(props) => (props.blankDot ? "1.2vw" : "1.1vw")};
  position: absolute;
  bottom: 0.7vw;
  right: 0px;
  /* transition: transform 2s; */
  opacity: ${(props) => (props.faller ? "0" : 1)};
  ${(props) =>
    props.fall &&
    css`
      opacity: 1;
      animation: 1s ${(props) => props.fall && fallAnim} ease-in forwards;
    `}
  ${(props) =>
    props.disappear &&
    css`
      display: none;
    `};
  @media (max-width: ${breakpoints.sm}) {
    width: 1.7vw;
    height: 1.7vw;
    bottom: 1.6vw;
  }
  @media (max-width: ${breakpoints.xs}) {
    width: 12px;
    height: 12px;
    bottom: 8px;
  }
`;

const Exclamation = () => {
  const [fall, setFall] = useState(false);

  const [style, trigger] = useBoop({
    x: 0,
    y: 0,
    rotation: 10,
    scale: 1.2,
    springConfig: { friction: 10, tension: 300 },
  });
  // useEffect(() => {
  //   setTimeout(() => {
  //     trigger();
  //   }, 1000);
  // }, []);

  // const handleTap = () => {
  //   trigger();
  //   if (taps < 3) {fall
  //     setTaps(taps + 1);
  //   }
  // };
  const handleTrigger = () => {
    if (!fall) {
      trigger();
    }
  };

  return (
    <Positioner>
      <Wrapper
        onClick={() => setFall(true)}
        style={style}
        onMouseEnter={handleTrigger}
        $fallen={fall}
      >
        !
        <Dot blankDot src={blankDot} />
        <Dot disappear={fall} src={dot} alt="" />
      </Wrapper>
      <Dot
        onClick={() => setFall(true)}
        style={{ cursor: "pointer" }}
        faller
        fall={fall}
        src={dot}
      />
    </Positioner>
  );
};

export default Exclamation;
