import evergreenBanner from "../assets/evergreenBanner.jpg";
import evergreenGeneralPic from "../assets/evergreenGeneralPic.png";
import evergreenPersona from "../assets/evergreenPersona.png";
import evergreenJM from "../assets/evergreenUJ.png";
import evergreenEM from "../assets/evergreenEM.png";
import evergreenSM from "../assets/evergreenSM.png";
import evergreenWF from "../assets/evergreenWF.png";
import evergreenLogos from "../assets/evergreenLogos.png";
import evergreenSS from "../assets/evergreenSS.png";
import evergreenUsability from "../assets/evergreenUsability.png";
import evergreenProto from "../assets/evergreenProto.jpg";
import evergreenComments from "../assets/evergreenComments.png";
import evergreenFP from "../assets/evergreenFP.png";

const evergreenData = {
  general: {
    banner: evergreenBanner,
    nextProject: "/project/columbia",
    previousProject: "/project/theatrSeatr",
    title: "EVERGREEN",
    subtitle: "UX & UI Design",
    description:
      "Evergreen is a mock wildlife rehabilitation center website that allows users to donate time, money, or goods to the rehabilitation’s cause. It’s directed primarily towards those of all ages, living in or visiting around the massachusettes area.",
    role: "Sole UX / UI Designer",
    duration: "3 Weeks",
    tools: ["Figma", "Adobe XD", "Canva"],
    responsibilities: [
      "Conducting surveys",
      "Digital wireframing",
      "Low and high fidelity prototyping",
      "Conducting usability studies",
      "Accounting for accessibility",
      "Iterating on designs and responsiveness",
    ],
    problem:
      "Most wildlife rehabilitation center websites consist of cluttered and chaotic designs with too little or overwhelming amounts of information, alongside confusing donation flows.",
    goal: "To make donating and finding information about the local wildlife fun, fast, and simple for all types of users.",
  },
  photos: [evergreenGeneralPic],
  prototype:
    "https://www.figma.com/proto/KSqgpnpxnOi9EI3nsKZknp/EvergreenWRC?node-id=62%3A187&scaling=scale-down&page-id=0%3A1&starting-point-node-id=62%3A187",
  accordion: {
    RESEARCH: [
      { title: "PRIMARY RESEARCH" },
      { subtitle: "INTERVIEWS" },
      {
        text: "I conducted 4 interviews, each lasting approximately 30 minutes long. I screened each participant to ensure that they either had an encounter with local wildlife and they needed outside advice, used a wildlife rehab site in the past for general wildlife or conservation information, or that they had donated to a wildlife rehab center, zoo, or some other type of conservation charity within the last 5 years. The goal of interviewing was to uncover the user pain points, motivators, and general feelings during their browsing and donating experiences. I also wanted to uncover user preferences regarding what information is most frequently saught out when visiting WRC’s and other charity sites, user’s donating habits, and their overall browsing habits.",
      },
      {
        text: "Some sample questions included:",
        style: { marginBottom: "12px" },
      },
      {
        ul: [
          "Can you describe the entire process for the last time you found an injured or orphaned animal?  *if applicable*",
          "Can you describe to me your most recent experience with using a wildlife rehab or conservation website?",
          "What information were you looking for when visiting the website?",
          "How often do you donate to wildlife/conservation charities?",
          "Are there any aspects of existing wildlife/conservation websites that you’ve used that you  particularly enjoy?",
          "Are there any aspects of existing wildlife/conservation websites that you’ve used that particularly frustrate you?",
        ],
      },
      { subtitle: "MOTIVATORS" },
      {
        ul: [
          "Several users reference a WRC when they encounter an orphaned or injured animal.",
          "Most users like a WRC or donation flow that includes the option to not only donate money, but also donate goods.",
          "Quality design (easily accessible information, aesthetics, navigation, flow) improves user perception and experience.",
          "It is important to many users that they are offered several ways get in touch with the WRC (email, phone, chat box).",
          "Users like several payment method options.",
        ],
      },
      { subtitle: "PAINS" },
      {
        ul: [
          "Difficulty identifying local species",
          "Donation button or page is not always easily identifiable.",
          "Inability to donate goods",
          "Struggle to find important information",
          "Cluttered, cold, and/or frustrating website layouts.",
        ],
      },
      { title: "PERSONA" },
      { image: { src: evergreenPersona, alt: "" } },
      {
        text: "Bridgette represents a primary group of users. I created this persona to keep the target users’ goals, pain points, and priorities in mind while moving forward in the design process.",
      },
      { title: "JOURNEY MAP" },
      { image: { src: evergreenJM, alt: "" } },
      {
        text: "By creating a  journey map, I am able to better understand the target audiences’ actions, thoughts, feelings, and frustrations during their experience using a WRC/conservation website and going through a donation flow. This helped me gain insights into common user pain points and how to improve them.",
      },
      { title: "EMPATHY MAP" },
      { image: { src: evergreenEM, alt: "" } },
      {
        text: "The empathy map gives me even further insight into where my focus should lie within the design. It also gives me insight as to what the user prioritizes and where they struggle throughout their browsing and donating process. A key insight that I took away from this process was that users want and need a very clear, accessible donation button.",
      },
    ],
    "ARCHITECTURE & INTERACTION": [
      { title: "SITE MAP" },
      { image: { src: evergreenSM, alt: "" } },
      {
        text: "This site map is a visual overview of how the WRC website is ultimately laid out. To avoid user frustration, I focused on the information architecture early on in the process to determine what information needed to be included and how to prioritize that information on each page.",
        style: { marginBottom: "8px" },
      },
      {
        text: "Depending on the user’s goals, they have several call-to-action’s that they’re able to choose from. They are able to travel between the home, about, FAQ, education, work with us, and donate pages quickly and simply. In the future I would like to implement a “more” dropdown menu, which will present three more CTA’s, including a user account, blog, and contact page. Within each page, there are also secondary pages that contain more pertinent information. The overall IA of this design is simple and to-the-point.",
      },
    ],
    WIREFRAMING: [
      { title: "WIREFRAMING" },
      { image: { src: evergreenWF, alt: "" } },
      {
        text: "I began the wireframing process with a few rough sketches on a whiteboard. The first few wireframes displayed the overall feel of the website and it’s donation flow. After getting the jist of which layouts worked best in terms of design, I transitioned my whiteboard drawings into digital wireframes on Adobe XD. The user flow of this website considers the aspects that are important to it’s users: an intuitive donation flow, clear navigation, a simple, uncrowded layout, and several ways to communicate with the WRC in the case of questions or emergencies.",
      },
    ],
    "STYLE GUIDE": [
      { title: "UI & BRANDING" },
      { subtitle: "Logo Design" },
      {
        wrap: [
          {
            text: "After skimming through many wildlife rehabilitation websites, I noticed that each organization tends to exhibit a strong sense of personality. I wanted to carry that sense of personality into my design as well. To maintain a fun and modern feel, while keeping the logo relevant to the organizations mission - I chose to make the logo a simple, brown fox.",
            style: { flex: "6", marginRight: "32px" },
          },
          {
            image: { src: evergreenLogos, alt: "" },
            style: { flex: "1", width: "200px" },
          },
        ],
        style: { display: "flex" },
      },
      { subtitle: "TYPOGRAPHY & COLOR" },
      { image: { src: evergreenSS, alt: "" } },
      {
        text: "Sticking to the fun yet modern feel of the website, I chose to use two complimentary sans-serif fonts: Staatliches and Sintony. A burnt orange staatliches font was selected as a header to represent the content on each of the pages. It gave the website a bit of personality without being too decorative or difficult to read. The Sintony font is used for both the content and the navigation bar. I chose this font because it paired well with the staatliches playfulness, yet it exhibits a modern feel and readability.",
      },
    ],
    "TESTING & ITERATIONS": [
      { title: "HIGH FIDELITY PROTOTYPES" },
      { image: { src: evergreenProto, alt: "" } },
      {
        text: "After applying my style guide to the wireframes, I finished the userflow by adding all the most important features and their supporting pages.",
        style: { marginBottom: "8px" },
      },
      {
        text: "The prototype demonstrates the user experience throughout the entire site thus far. I organized the screens on figma by their flows, heirarchy, and by how they will be used in the prototype phase (states), such as “overlays”, “chatboxes”, “main flow” and “active”.",
      },
      { title: "USABILITY TESTS" },
      {
        image: { src: evergreenUsability, alt: "" },
        style: { maxWidth: "800px", margin: "0px auto 64px" },
      },
      {
        text: "Using my prototype,  I conducted a usability test to see how successful my design was. I launched this test with Maze and recieved 10 participants over a one day period. The goal of this usability test was to uncover if the design was usable, intuitive, and discoverable.",
        style: { marginBottom: "8px" },
      },
      {
        text: "I was specifically interested in examining the success of the user’s interaction with the chat box, their success with navigating throughout the website, and most importantly, the success of the donation flow. According to users, these were the most important aspects to a wildlife rehab center/conservation website.",
      },
      { subtitle: "Overview:" },
      {
        text: "I presented participants with the following tasks:",
        style: { marginBottom: "8px" },
      },
      {
        ol: [
          "From the home screen, please open a chat and start chatting with a representative.",
          "You found an orphaned chipmunk, oh no! Head to the FAQ page and find information as to what to do next.",
          "You've read Evergreen's mission on the Home screen and now you'd like to Donate to their cause. Starting at the home screen, please begin your journey making a one-time donation of $100 to Evergreen.",
        ],
      },
      {
        text: "Once the participants completed each task, I asked them how intuitive the task felt  using an opinion scale (1= not at all, 10 = very intuitive). ",
      },
      { subtitle: "Findings:" },
      {
        text: "The usability test validated my design decisions. Participants had no trouble with the site flows. There was no drop offs and only a 2.5% misclick rate between all three missions. One participant had misclicked several times during the donation mission, due to a maze loading issue.  With the average score of a 9.2 out of 10 on the opinion scale, participants felt the first task was very intuitive. The second task averaged an 8 out of 10, and the third task averaged an 8 out of 10.",
      },
      { subtitle: "Further Questioning:" },
      {
        text: "To get more insight on the design from the participants, I asked them a couple more follow-up questions:",
        style: { marginBottom: "8px" },
      },
      {
        ul: [
          "Was there anything about the website that you particularly enjoyed?",
          "Was there anything about the website that you found particularly frustrating?",
          "Did you find the text / color palette legible? (Yes or No)",
          "Do you have any additional comments?",
        ],
      },
      {
        text: "If the participants were not interested in answering the follow-up, open answer questions they had the option to comment “N/A”. Most participants decided to opt out of these questions, however a few participants did respond with kind messages or informative insights to consider in my next round of iterations. Two participants mentioned that finding animal related information under the FAQ page was not particularly intuitive to them. To avoid this confusion in the future, I will consider making a new page that will be specifically dedicated to animal care questions.",
      },
      { image: { src: evergreenComments, alt: "" } },
    ],
    "FINAL DESIGN": [
      { title: "EVERGREEN" },
      {
        image: { src: evergreenFP, alt: "" },
        style: { maxWidth: "800px", margin: "0px auto 64px" },
      },
      {
        text: "The Evergreen Wildlife Rehabilitation website focuses on the modern online donation system and browsing experience within a non-profit, conservation and/or animal rehabilitation organization. The user is welcomed by the home screen where they will find the organization’s mission statement and any updates. They can take several different actions, including: learning more about the rehab center via the about page, looking over the frequently asked questions by clicking FAQ, indulging in the learning opportunities that they offer by viewing the education page, scanning the work with us page for volunteer work/internships, or clicking the donation button to start a donation flow.  On the home page and throughout the design, call-to-actions’s encourage the user to browse through the content and ultimately donate to the cause.",
      },
      {
        text: "To see the full application:",
        style: { alignSelf: "center", marginBottom: "32px" },
      },
      {
        buttonLink: {
          text: "GO TO PROTOTYPE",
          link: "https://www.figma.com/proto/KSqgpnpxnOi9EI3nsKZknp/EvergreenWRC?node-id=62%3A187&scaling=scale-down&page-id=0%3A1&starting-point-node-id=62%3A187",
        },
        style: { alignSelf: "center", marginBottom: "64px" },
      },
    ],
  },
  finalText:
    "I designed Evergreen with the intention to grow my skills designing simple, intuitive desktop sized web pages with more demand on usability, functionality, and information architecture. Designing for a non-profit expanded my understanding of the industry and all it’s complexities. My research and usability testing provided me with important insight into how to avoid overwhelming the users with too much content and too many features.",
};
export default evergreenData;
