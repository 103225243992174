import React from "react";
import styled from "styled-components";
import backArrow from "../assets/backArrow.svg";
import frontArrow from "../assets/frontArrow.svg";

const FrontArrow = styled.img`
  width: 64px;
  height: 64px;
  position: absolute;
  transform: ${(props) => props.position};
  transition: transform 0.2s ease;
`;
const BackArrow = styled.img`
  height: 64px;
  width: 64px;
  position: absolute;
  transform: ${(props) => props.position};
  transition: transform 0.2s ease;
`;
const Line = styled.div`
  position: absolute;
  width: 1px;
  height: 14px;
  background-color: black;
  transition: transform 0.2s ease;
  ${(props) => props.position}
`;
const Wrapper = styled.div`
  cursor: pointer;
  position: relative;
  height: 64px;
  width: 64px;
  &:hover {
    ${FrontArrow}, ${BackArrow} {
      transform: translate(0px, 0px)
        ${(props) => (props.left ? "rotate(180deg)" : "")};
    }
    ${Line} {
      transform: rotate(${(props) => (props.left ? "" : "-")}65deg) scale(0);
    }
  }
`;
const CoolArrow = ({ className, left }) => {
  const rightArrowPositions = {
    tr: "top: -6px; right: 32px; transform: rotate(-65deg);",
    br: "bottom: -5px; right: 32px; transform: rotate(-65deg);",
    tl: "top: 9px; left: 1px; transform: rotate(-65deg);",
    bl: "bottom: 9px; left: 1px; transform: rotate(-65deg);",
    backArrow: "translate(-6px, -3px)",
    frontArrow: "translate(6px, 3px)",
  };
  const leftArrowPositions = {
    tr: "top: 9px; right: 1px; transform: rotate(65deg);",
    br: "bottom: 9px; right: 1px; transform: rotate(65deg);",
    tl: "top: -5px; left: 32px; transform: rotate(65deg);",
    bl: "bottom: -6px; left: 32px; transform: rotate(65deg);",
    backArrow: "translate(6px, -3px) rotate(180deg)",
    frontArrow: "translate(-6px, 3px) rotate(180deg)",
  };

  return (
    <Wrapper className={className} left={left}>
      <BackArrow
        src={backArrow}
        position={
          left ? leftArrowPositions.backArrow : rightArrowPositions.backArrow
        }
        alt=""
      />
      <FrontArrow
        src={frontArrow}
        position={
          left ? leftArrowPositions.frontArrow : rightArrowPositions.frontArrow
        }
        alt=""
      />
      <Line position={left ? leftArrowPositions.tr : rightArrowPositions.tr} />
      <Line position={left ? leftArrowPositions.br : rightArrowPositions.br} />
      <Line position={left ? leftArrowPositions.tl : rightArrowPositions.tl} />
      <Line position={left ? leftArrowPositions.bl : rightArrowPositions.bl} />
    </Wrapper>
  );
};

export default CoolArrow;
