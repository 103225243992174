import Phone0 from "../assets/theatrPhone0.png";
import Phone1 from "../assets/theatrPhone1.png";
import Phone2 from "../assets/theatrPhone2.png";
import Phone3 from "../assets/theatrPhone3.png";
import Phone4 from "../assets/theatrPhone4.png";
import theatrSeatrCA from "../assets/theatrSeatrCA.png";
import theatrSeatrUP from "../assets/theatrSeatrUP.png";
import theatrSeatrJM from "../assets/theatrSeatrJM.png";
import theatrSeatrSM from "../assets/theatrSeatrSM.png";
import theatrSeatrBanner from "../assets/theatrSeatrBanner.png";
import theatrSeatrWireframing1 from "../assets/theatrSeatrWireframing1.png";
import theatrSeatrWireframing2 from "../assets/theatrSeatrWireframing2.png";
import theatrSeatrLogo1 from "../assets/theatrSeatrLogo1.png";
import theatrSeatrLogo2 from "../assets/theatrSeatrLogo2.png";
import theatrSeatrColorTypography from "../assets/theatrSeatrColorTypography.png";
import theatrSeatrMoodBoard from "../assets/theatrSeatrMoodBoard.jpg";
import theatrSeatrUiKit from "../assets/theatrSeatrUiKit.png";
import theatrSeatrPrototype from "../assets/theatrSeatrPrototype.png";
import theatrSeatrHeatmap from "../assets/theatrSeatrHeatmap.png";
import theatrSeatrUsability1 from "../assets/theatrSeatrUsability1.png";
import theatrSeatrUsability2 from "../assets/theatrSeatrUsability2.png";
import theatrSeatrLocationChange from "../assets/theatrSeatrLocationChange.png";
import theatrSeatrFinalDesign1 from "../assets/theatrSeatrFinalDesign1.png";
import theatrSeatrFinalDesign2 from "../assets/theatrSeatrFinalDesign2.png";
import theatrSeatrFinalDesign3 from "../assets/theatrSeatrFinalDesign3.png";

const theatrSeatrData = {
  general: {
    banner: theatrSeatrBanner,
    nextProject: "/project/evergreen",
    previousProject: "/project/columbia",
    title: "THEATR SEATR",
    subtitle: "UX & UI Design",
    description:
      "Theater Seater is an app that allows users to purchase movie tickets and treats on-the-go. It provides a simple, intuitive and efficient user-flow, making the movie-going experience contactless and, of course, fun!",
    role: "Sole UX / UI Designer",
    duration: "8 Weeks",
    tools: ["Figma", "Canva"],
    responsibilities: [
      "Conducting surveys",
      "Paper and digital wireframing",
      "Low and high fidelity prototyping",
      "Conducting usability studies",
      "Accounting for accessibility",
      "Iterating on designs and responsiveness",
    ],
    problem:
      "Generally people would like an app where they can plan their movie-going experience ahead of time to avoid unnecessary lines, to choose their seating, and buy their concessions. Most ticketing focused apps that already exist, are not specific to the movie industry and often do not include independent theaters. Other apps in this category don’t consider concessions and don’t allow the user an option to share reservations with friends. They typically have cluttered design and contain clunky checkout processes.",
    goal: "Design a user-centered app for movie ticketing that allows concession ordering and social capabilities while providing clear navigation and a simple, fast, and engaging checkout process.",
  },
  photos: [Phone0, Phone1, Phone2, Phone3, Phone4],
  prototype:
    "https://www.figma.com/proto/zKwaFkLNW5M0ZLBqbLssYZ/Untitled?node-id=2%3A4148&scaling=scale-down&page-id=2%3A414&starting-point-node-id=2%3A1732",
  accordion: {
    RESEARCH: [
      { title: "SECONDARY RESEARCH" },
      {
        ul: [
          "The COVID-19 pandemic has, and will continue to, impact consumer behavior. Trends like online ordering, distanced commerce, and investment in digital strategies are projected to become even more frequently used as technology continues to advance.",
          "There was already a rise of online ticket sales before the pandemic and this trend has only compounded with COVID-19. Customer pain points for buying movie tickets at the theater included: waiting in lines, limited seat selection at time of purchase, inability to purchase refreshments ahead of time, and lack of contactless options. Limiting human exposure and creating contactless opportunites help prevent the spread of the COVID-19 virus, therefore, creating an app that depleats the need for these practices is necessary and crucial.",
          "According to a market research done by Vertigo, online purchasers were 2.1x more likely to buy concessions and (where applicable) 1.7x more likely to buy a meal compared to the at-theater ticket purchasers. In 2018, the study witnessed a 44% increase of moviegoers who bought online that watched films on a 2D premium screen format. 3D, Premium 3D, and IMAX also saw more moviegoers who purchased online. ",
          "Comfort, which would include the type of seating, and reserved seating became major factors when selecting a theater to online ticket buyers. 28% of online ticket shoppers said they picked the theater because of reserved seating, while only 7.6% of those who bought at the theater listed that as a reason. 36% of moviegoers surveyed picked their theater because of comfort, compared to only 25.9% of offline buyers.",
          "As the notion of moviegoing as an impulse choice or a spur-of-the-moment casual entertainment option declines in popularity, it is expected that we see a larger percentage of tickets reflected in online sales and reflected in premium offerings (IMAX, 3-D, Dolby Cinema, D-Box and general high-end multiplexes).",
        ],
      },
      { title: "PRIMARY RESEARCH" },
      { subtitle: "Surveys" },
      {
        text: "I conducted a survey and recieved 10 respondents. The goal of the survey was to uncover the user pain points and motivators during the movie-going and ticket/concession purchasing experience. I also wanted to uncover consumer preferences in regards to seat selection, concession ordering habits, and their experience with existing online movie ticket purchasing apps.  ",
      },
      { subtitle: "Motivators" },
      {
        ul: [
          "Over half of the participants considered seat selection when purchasing a movie ticket.",
          "About 75% of participants considered location and proximity to a theater as a top priority.",
          "Quality design (easily accessible information, aesthetics, navigation, flow) improves user perception and experience.",
          "Most users like a“continue as a guest” option when checking out.",
          "Almost all users would sign up as a member of an app to buy their movie tickets and concessions online if promos or deals were offered.",
          "85% of the users surveyed plan their trip to the movie theater rather than it being a spur-of-the-moment decision and order their tickets online.",
          "About 30% of users consider a contactless experience when going to the theater.",
        ],
      },
      { subtitle: "Pains" },
      {
        ul: [
          "Long lines and waits for ticket and concessions purchasing",
          "Inability to reserve seating (especially for larger parties trying to sit together)",
          "Cluttered and unappealing websites / apps.",
          "Forced to sign-up / sign-in.",
          "Difficulty finding important information.",
          "Clunky checkout processes.",
          "Lack of app and website with concession ordering options.",
        ],
      },
      {
        image: {
          src: theatrSeatrCA,
          alt: "",
        },
      },
      {
        text: "I conducted a competitor analysis to see where other movie ticket apps could use improvement and noticed that very few existing apps include a filter page to make movie searching more efficient, and they also rarely include the ability to share the movie / reservation with friends. Lastly, many existing movie ticketing apps are produced by the theater they represent, or similar to larger companies such as Fandango (a third party), they do not include independent theaters in their nearby theater options. ",
        style: { marginBottom: "96px" },
      },
      { title: "USER PERSONA" },
      {
        image: {
          src: theatrSeatrUP,
          alt: "",
        },
      },
      {
        text: "I also created a user persona to represent a group of users and to keep their goals and frustrations in mind while moving forward in the design process.",
      },
      { image: { src: theatrSeatrJM, alt: "" } },
      {
        text: "I created a journey map to better understand Stephanie’s thoughts, feelings, and frustrations during a typical online movie ticket purchasing experience. This helped me gain insights into common customer pain points and how to improve them.",
      },
    ],
    "ARCHITECTURE & INTERACTION DESIGN": [
      { title: "SITE MAP" },
      { image: { src: theatrSeatrSM, alt: "" } },
      {
        style: { marginBottom: "16px" },
        text: "I created a sitemap to provide an overview of the designed information architecture. From the home screen, which duals as the screen that displays the new releases / now playing in theaters films - the user has five options or actions that they can take (highlighted in yellow). From here there are six different pages they can land on (highlighted in white). By choosing a movie from the home screen, the user is then taken into a sequential userflow that directs them to choose a theater nearby, pick a showtime, reserve seats, order concessions (or skip them), then leads them to the checkout flow. The user also has the ability to go back to the previous page at anytime throughout this flow, if desired.",
      },
      {
        style: { marginBottom: "16px" },
        text: "The main userflow is primarly laid out sequentially, similar to many of the existing ticket purchasing apps. I decided to stick to this trend because it prevents the ticket and concession buying experience from offering too many options, which can often lead to an overwhelming user experience. It encourages the use of the apps navigation, and increases conversion rates while limiting the drop-off rate.",
      },
      {
        style: { marginBottom: "16px" },
        text: "Based on research that suggests account creation can improve customer retention, I chose to include the option to both continue as a guest and the ability to sign-in or sign-up at the very front of this flow (under the account tab), and at the very end of this flow (during the checkout process).",
      },
    ],
    WIREFRAMING: [
      { title: "WIREFRAMING" },
      { image: { src: theatrSeatrWireframing1, alt: "" } },
      {
        text: "Above are some hand-drawn wireframes that I slapped together early on in the process to represent a very bare-bones, main userflow. To set the app apart from existing movie-ticketing apps, I ensured that it included filters, concessions, and the ability for users to choose their seats.",
      },
      {
        text: "The user starts at a splash page that welcomes them to the app, then it lightly fades into a screen that asks them for their preferred location, once they choose their location, they are directed to the Home / Now Playing page. From there the user has three options to narrow their search for a movie. These options are in the form of CTA's. They include choosing a location, filtering information, and the ability to search the exact movie they're looking for. By choosing a movie, the user will automatically be led into a checkout flow. They are able to travel through the flow in reverse as well in case the user makes a mistake or has a change of heart in their movie, seat,  concession, or payment selections. ",
      },
      { image: { src: theatrSeatrWireframing2, alt: "" } },
      {
        text: "Satisfied with the rough sketches of the app, I decided to turn them into digital wireframes. This provided me with a more accurate representation of what the app will actually roughly look and feel like. The userflow of this app considers most of the apsects that are important to it’s users: seat selection, pre-ordering concessions, and a straight forward checkout process. However, I ended up changing a few things in the mock-up stage of my design process, as you can see on the testing and iterations page.",
      },
    ],
    "UI & BRANDING": [
      { title: "HIGH FIDELITY PROTOTYPES" },
      { subtitle: "LOGO DESIGN" },
      {
        wrap: [
          {
            text: "Most existing movie ticketing apps are impersonable and have a cold, corporate feeling. To warm it up a bit, I gave Theatr Seatr a personality to make it more visually exciting and inviting to the user by incorporating 1930’s american rubberhose cartoons. This design is both simple and endearing.",
            style: { flex: "3", marginRight: "40px" },
          },
          {
            image: { src: theatrSeatrLogo1, alt: "" },
            style: {
              width: "initial",
              flex: "1",
              marginRight: "16px",
              maxWidth: "120px",
            },
          },
          {
            image: { src: theatrSeatrLogo2, alt: "" },
            style: { width: "initial", flex: "1", maxWidth: "120px" },
          },
        ],
        style: { display: "flex" },
      },
      { subtitle: "TYPOGRAPHY & COLOR" },
      { image: { src: theatrSeatrColorTypography, alt: "" } },
      {
        text: "My typography and UI kit is also inspired by vintage elements. I chose Modak, a decorative font, as the header for each page to compliment the rubberhose cartoon aesthetic. I paired it with Thonburi, a simple sans-serif font, to prevent the user interface from being overwhelming and difficult to read. I also chose to differentiate between the text on each page and the text of the buttons. This helps draw the eye to the buttons and prevents confusion as to where the user is able to click. I use Mutka for the CTA’s, another basic sans-serif font that offered a stronger bold option.",
      },
      { subtitle: "MOOD BOARD" },
      {
        wrap: [
          {
            image: { src: theatrSeatrMoodBoard, alt: "" },
            style: { flex: "2", marginRight: "32px", height: "500px" },
          },
          {
            text: "While trying to choose an aesthetic, I conducted a “vintage movie theater” google search and found some of the pictures that gave me inspiration for both my color palette and the overall retro feel.",
            style: { flex: "3" },
          },
        ],
        style: { display: "flex" },
      },
      { subtitle: "UI KIT" },
      { image: { src: theatrSeatrUiKit, alt: "" } },
      {
        text: "To create a UI Kit, I compiled a list of all the UI elements that I would need to design for the Theatr Seatr app and stylized each one of them. The UI kit gives a clear guide on how to treat each component’s states.",
        style: { marginBottom: "32px" },
      },
      {
        text: "Creating the UI kit, I decided to stick with the original color palette to keep the design consistent, intuitive, and accessible.",
      },
    ],
    "TESTING & ITERATION": [
      { title: "HIGH FIDELITY PROTOTYPES" },
      {
        wrap: [
          {
            wrap: [
              {
                text: "After applying my style guide and UI kit to the wireframes, I finished the userflow by adding all the important features and their supporting pages.",
                style: { marginBottom: "32px" },
              },
              {
                text: "The prototype demonstrates the user experience throughout the entire site. I organized the flows by their states, heirarchy, and by how they will be used in the prototype phase, such as “default”, “secondary pages”, “active”, and “overlays”.",
              },
            ],
            style: { flex: "2", marginRight: "16px" },
          },
          {
            image: { src: theatrSeatrPrototype, alt: "" },
            style: { flex: "3", height: "400px" },
          },
        ],
        style: { display: "flex" },
      },
      { title: "USABILITY TESTS" },
      {
        text: "Using my prototype, I conducted usability tests to see how successful my design was. I launched this test with Maze and recieved 45 participants over a three day period. The goal of this usability test was to learn how to conduct one properly (it was my first time!), and more importantly to uncover how usable, ituitive, and discoverable the design was.",
        style: { marginBottom: "32px" },
      },
      {
        text: "I was specifically interested in examining the success of online ticketing, concession ordering, and seat selection flows. According to users, these are the most important aspects of an online ticketing app.",
      },
      { subtitle: "USABILITY TEST #1" },
      {
        text: "I presented participants with the following tasks:",
        style: { marginBottom: "16px" },
      },
      {
        ul: [
          "From the home screen (or Now Playing screen), please search for a movie.",
          "From the home screen (or Now Playing screen), please sign up for a Theatr Seatr account.",
          "From the home screen (or Now Playing screen), please reserve a seat and purchase a movie ticket.",
        ],
        style: { marginBottom: "32px" },
      },
      {
        text: "Because participants had issues reserving a seat, they could not purchase a ticket. This was due to my own misunderstanding of the Maze app at the time.",
      },
      {
        wrap: [
          {
            image: { src: theatrSeatrHeatmap, alt: "" },
            style: {
              flex: "1",
              marginRight: "36px",
              maxWidth: "200px",
            },
          },
          {
            wrap: [
              {
                text: "As you can see from the heatmap, users had a tough time finding which seat was available for clicking, making the drop off rate higher than expected. Using what I learned from my (very) first usability test, I made some prototype iterations and conducted another usability test with more detailed instructions.",
                style: { marginBottom: "32px" },
              },
              {
                text: "Although I experienced a setback, the usability test validated the majority of my designs decisions.",
              },
            ],
            style: { flex: "5" },
          },
        ],
        style: { display: "flex" },
      },
      {
        subtitle: "Further questions:",
        style: { marginBottom: "0px", fontSize: "18px" },
      },
      {
        text: "Once the participants completed the tasks, I asked them to respond to the following questions:",
        style: { marginBottom: "16px" },
      },
      {
        ul: [
          "How legible did you find the color palette?",
          "Were there any aspects of the design / flow that you particularly liked or enoyed?",
          "Were there any aspects of the design / flow that you particularly disliked or found frustrating?",
          "Any additional questions (N/A if you’d rather not participate)",
        ],
        style: { marginBottom: "32px" },
      },
      {
        text: "Almost all users found the color palette very legible and representative of a movie theater feel. Many participants noticed the prototype issue and brought it to my attention. One user suggested a key to distinguish which seats were available, taken, and selected by the user. I took this into consideration in my iterations going forward.",
      },
      { subtitle: "ITERATIONS #1" },
      {
        text: "After the feedback I recieved, I went into my design and made the entire theater layout clickable. I updated my research questions to make them more specific, while keeping them vague enough to avoid dictating the results.",
      },
      { subtitle: "USABILITY TEST #2" },
      {
        text: "I launched this usability test also with Maze and recieved 13 participants over 2 days.",
      },
      {
        wrap: [
          {
            wrap: [
              {
                text: "This usability test showed a 93% success rate for the first task: looking over movie details. Signing up for a Theatr Seatr account also deemed successful with 80% of participants having no trouble with the task, but saw only a 60% success rate (both directly and indirectly) for the last and most important task: purchasing a ticket. The drop off rate improved in this task between the two tests, however, they were still not up to where I wanted them to be.",
                style: { marginBottom: "16px" },
              },
              {
                text: "The participants were having issues on the showtimes page (indicated by the pictures of the participants taps), an area in my design that hadn’t shown any issues in the previous test and a screen in which I hadn’t made any design changes.",
              },
            ],
            style: { flex: "2", marginRight: "16px" },
          },
          {
            image: { src: theatrSeatrUsability1, alt: "" },
            style: { flex: "1", height: "400px" },
          },
        ],
        style: { display: "flex", alignItems: "flex-start" },
      },
      {
        text: "A couple of participant's mentioned at the end of the usability test that it was unclear to them that they were required to input their location and date in order to see the theaters and showtimes available to them. To avoid this problem in the future, I will ask the users in the beginning of the flow to choose their location and the app will autofill to “today's date”.",
      },
      {
        text: "One participant stated that they were frustrated with the location of the navigation bar (at the top of the screen), and that it made it hard to reach. I plan to move the navigation bar to the bottom to make it more accessible.",
      },
      { subtitle: "ITERATIONS #2" },
      {
        wrap: [
          {
            image: { src: theatrSeatrUsability2, alt: "" },
            style: { flex: "2", marginRight: "32px", height: "400px" },
          },
          {
            text: "I adjusted the navigation bar by placing it at the bottom of the screen so that it is more accessible. To avoid confusion on the showtimes page, I took the participants feedback and require the user to choose their location earlier in the userflow. The screens portray the change in position of the navigation bar from the top of the screen down to the bottom.",
            style: { flex: "3" },
          },
        ],
        style: { display: "flex" },
      },
      {
        text: "The screens below show that I adjusted the location input screen from the middle of the flow to the beginning, making it less confusing later in the flow for the user to find the available theaters and showtimes in their area.",
      },
      {
        image: { src: theatrSeatrLocationChange, alt: "" },
        style: { width: "initial", margin: "0px auto 32px" },
      },
    ],
    "FINAL DESIGN": [
      {
        title: "THEATR SEATR",
      },
      { image: { src: theatrSeatrFinalDesign1, alt: "" } },
      {
        text: "The Theatr Seatr app focuses on the modern online ordering system for movie tickets and concessions. The splash screen welcomes the user, which softly fades into asking the user for their location. Once the user inputs their location, they will be able to browse the movies playing in that area. The home screen allows the user to take several different actions, including the ability to change their location, filter through movies, go to their profile (or make one), and search for a specific movie. On the homescreen and throughout the design, call-to-actions’s encourage the user to browse through movies and ultimately purchase tickets online.",
      },
      { image: { src: theatrSeatrFinalDesign2, alt: "" } },
      {
        text: "Once the user chooses a movie, they are able to tab through the movie details, showtimes, and reviews. The tab changes color when tapped providing clearity to the user as to what information is being displayed. The user is also able to specify which film format they prefer. After a showtime is chosen, movie and theater details are displayed at the top of the screen. Below is a layout of the theater. This is where the user is prompted to choose their seat(s) by tapping the seats (circles), that are indicated by the key below as available. From there the user will either add concessions alongside their ticket purchase or skip ahead and begin the checkout flow. If the user chooses to purchase concessions, they are able to tap on any of the icons on the concessions page and this will bring them to a screen with several item options and an item counter. This allows them to add multiple items at a time. When the user adds an item, an “Item Added” banner will flash across the screen. This gives the user immediate feedback, making them feel secure that their action was noticed and successful. From this page, the user will tap the next button. They will be directed to a pick-up scheduling screen. Once the user inputs their preferred name and pick-up time, they will tap the next button to see their order details.",
      },
      { image: { src: theatrSeatrFinalDesign3, alt: "" } },
      {
        text: "The check out flow begins with an order details page, allowing the user an overview of the movie, seat(s), and concessions (if any) that they chose, along with the opportunity to edit any of these decisions. If the user has not signed in, the “Looks good!” button automatically progresses them into the check out flow as a guest. However, because research suggests that account creation improves customer retention rates, they are still provided the opportunity to sign in or sign up as well. If the user has an account, the order details page will display a different CTA at the bottom, this will depend on the payment methods they have chosent to store in their account. After inputing all their personal information, the user will tap the “Confirm Order” button (if they are not signed in), otherwise the user will tap to use Applepay, Paypal, or another stored payment method, which will confirm the order. Confirming the order brings the user to the final screen, “Order Submitted”. On this screen, the user recieves further instructions regarding their proof of purchase. It also allows the user to add the ticket to a virtual wallet, share it with friends, and/or add it to their calendar.",
      },
      {
        text: "To see the full application:",
        style: { alignSelf: "center", marginBottom: "32px" },
      },
      {
        buttonLink: {
          text: "GO TO PROTOTYPE",
          link: "https://www.figma.com/proto/zKwaFkLNW5M0ZLBqbLssYZ/Untitled?node-id=2%3A4148&scaling=scale-down&page-id=2%3A414&starting-point-node-id=2%3A1732",
        },
        style: { alignSelf: "center", marginBottom: "64px" },
      },
    ],
  },
  finalText:
    "Theater Seater was my very first UX design project while completing my Google/Coursera UX Design Professional Certificiation. I began this project with very basic knowlege of UX concepts and a slightly scattered design process. However, I came out with an enjoyable, dynamic designed application, and significantly more confidence in wireframing, prototyping, empathizing with the user, and design thinking / problem solving. I also grew my skillset in ecommerce design. If I had more time, I would have also designed a website to accompany the app and I would have done another usability test to confirm that my most recent iterations were, at the very least, more successful than the last and perhaps uncover some more areas for improvement.",
};
export default theatrSeatrData;
