import React from "react";
import styled from "styled-components";
import { colors } from "../constants";

const ContentBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  border: 3px solid black;
  transition: transform 0.4s ease;
  overflow: hidden;
`;
const BackBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  border: 3px solid black;
  transition: transform 0.4s ease;
`;
const Line = styled.div`
  width: 3px;
  height: var(--lineSize);
  background-color: black;
  position: absolute;
  transform: rotate(-45deg) scaleY(1);
  transition: transform 0.4s ease;
  ${(props) => props.position}
`;
const Wrapper = styled.div`
  isolation: isolate;
  position: relative;
  width: var(--boxWidth);
  height: var(--boxHeight);
  /* padding: 20px; */
`;
const Variables = styled.div`
  --boxSpaceBack: ${(props) => props.sizing.boxSpaceBack};
  --boxSpaceFront: ${(props) => props.sizing.boxSpaceFront};
  --boxHeight: ${(props) => props.sizing.boxHeight};
  --boxWidth: ${(props) => props.sizing.boxWidth};
  --lineSize: ${(props) => props.sizing.lineSize};
  --bg-color: ${(props) => props.bgColor || colors.primaryHighlight};
  z-index: ${(props) => props.zIndex};
  ${(props) => {
    if (props.reverse) {
      return `
      ${BackBox} {
        transform: translate(var(--boxSpaceBack),var(--boxSpaceBack))
      }
      ${ContentBox} {
        transform: translate(var(--boxSpaceFront), var(--boxSpaceFront))
      }
      &:hover {
        ${BackBox}, ${ContentBox} {
          transform: translate(0px, 0px);
        }
        ${Line} {
          transform: rotate(-45deg) scaleY(0);
        }
      }
      `;
    } else {
      return `
      ${Line} {
        transform: rotate(-45deg) scaleY(0)
      }
      &:hover {
        ${ContentBox} {
          transform: translate(var(--boxSpaceFront), var(--boxSpaceFront));
        }
        ${BackBox} {
          transform: translate(var(--boxSpaceBack), var(--boxSpaceBack));
        }
        ${Line} {
          transform: rotate(-45deg) scaleY(1);
        }
      }
      `;
    }
  }};
`;
export const CoolBox = ({
  children,
  reverse,
  sizing = {
    boxSpaceBack: `-20px`,
    boxSpaceFront: `20px`,
    boxWidth: `400px`,
    boxHeight: `400px`,
    linePositionTL: `top: -27px; left: 0px;`,
    linePositionTR: `top: -27px; right: 0px;`,
    linePositionBL: `bottom: -27px; left: 0px;`,
    lineSize: `56px`,
  },
  zIndex,
  className,
  style,
  bgColor,
}) => {
  return (
    <Variables
      bgColor={bgColor}
      reverse={reverse}
      style={style}
      zIndex={zIndex}
      sizing={sizing}
    >
      <Wrapper>
        <BackBox boxWidth={sizing.boxWidth} boxHeight={sizing.boxHeight} />
        <ContentBox
          className={className}
          boxWidth={sizing.boxWidth}
          boxHeight={sizing.boxHeight}
        >
          {children}
        </ContentBox>
        <Line lineSize={sizing.lineSize} position={sizing.linePositionTL} />
        <Line lineSize={sizing.lineSize} position={sizing.linePositionTR} />
        <Line lineSize={sizing.lineSize} position={sizing.linePositionBL} />
      </Wrapper>
    </Variables>
  );
};
