import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import curveBg from "../../assets/curveBackground.png";
import CoolArrow from "../../components/CoolArrow";
import FlexContainer from "../../components/FlexContainer";
import Page from "../../components/Page";
import {
  BigText,
  breakpoints,
  colors,
  fadeIn,
  fonts,
  Heading,
  PageTitle,
} from "../../constants";
import { Bubble } from "../About";
import Banner from "./Banner";

const StyledPage = styled(Page)`
  height: auto;
  padding-bottom: 200px;
  padding-top: 80px;
  position: relative;
  /* @media (max-width: ${breakpoints.sm}) {
    padding-bottom: 100px;
    padding: 80px 24px 100px;
  } */
`;
const Title = styled(PageTitle)`
  margin-bottom: -12px;
  font-family: ${fonts.primary};
  letter-spacing: 2px;
`;
const Subtitle = styled(Heading)`
  padding: 16px 0px;
  color: ${colors.primaryHighlight};
  font-family: ${fonts.secondary};
  font-size: 1.5rem;
  @media (max-width: ${breakpoints.md}) {
    font-size: 1.2rem;
  }
`;
const DescText = styled(BigText)`
  padding-bottom: 36px;
`;
const SectionWrap = styled(FlexContainer)`
  width: 100%;
  padding: 120px 0px;
  row-gap: 60px;
  max-width: 1440px;
  margin: 0px auto;
  @media (max-width: ${breakpoints.md}) {
    padding: 120px 24px;
  }
  @media (max-width: ${breakpoints.sm}) {
    padding: 160px 24px;
  }
`;
const DescSection = styled.article`
  flex: 1 1 33%;
  height: auto;
  min-width: 280px;
  padding: 0px 20px;
  @media (max-width: ${breakpoints.xs}) {
    padding: 0px;
  }
  /* max-width: 400px; */
`;
const SectionHeading = styled(Heading)`
  margin-bottom: 12px;
`;
const SectionText = styled.p`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 1rem;

  max-width: 400px;
`;
const SectionList = styled.ul`
  font-family: ${fonts.secondary};
  font-size: 1rem;
  list-style: none;
  padding: 0px;
`;
const SectionListItem = styled.li`
  display: flex;
  align-items: center;
  &:before {
    content: "";
    width: 2px;
    height: 2px;
    background-color: black;
    border-radius: 50%;
    margin-right: 8px;
  }
`;
const BannerWrap = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  /* grid-template-areas: "leftArrow banner rightArrow"; */
  align-items: center;
  margin-bottom: 32px;
  /* @media (max-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "banner banner" "leftArrow rightArrow";
    gap: 48px;
  } */
  @media (max-width: ${breakpoints.md}) {
    padding: 0px 24px;
  }
`;
const OverflowWrap = styled.div`
  overflow: hidden;
  position: relative;
  border: 4px solid black;
  border-radius: 26px;
  max-width: 100%;
  @media (max-width: ${breakpoints.sm}) {
    /* display: none; */
  }
`;
const LeftArrow = styled(CoolArrow)`
  margin-right: 24px;
`;
const RightArrow = styled(CoolArrow)`
  margin-left: 24px;
`;
const FadeWrap = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StyledFlexContainer = styled(FlexContainer)`
  opacity: 0;
  animation: 0.8s ${fadeIn} ease-in forwards;
  animation-delay: 0.2s;
  position: relative;
`;
const ArrowLink = styled(Link)`
  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;
const BgImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  z-index: -1;
`;
const ConstrainWrap = styled.div`
  width: 100%;
  position: relative;
`;
const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  @media (max-width: ${breakpoints.md}) {
    padding: 0px 24px;
  }
`;

const fadeVariants = {
  enter: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const Description = ({ data }) => {
  const location = useLocation();
  return (
    <StyledPage>
      <StyledFlexContainer direction="column" alignItems="center">
        <Bubble
          height="400px"
          width="400px"
          position={"top: -200px; left: 23%;"}
        />
        <BannerWrap>
          {/* <ArrowLink
            to={data.previousProject}
            state="previous"
            justify="flex-end"
          >
            <LeftArrow left />
          </ArrowLink> */}
          <OverflowWrap>
            <img style={{ opacity: 0 }} src={data.banner} alt="" />
            <Banner direction={location.state} activeImg={data.banner} />
          </OverflowWrap>
          {/* <ArrowLink justify="flex-start" to={data.nextProject} state="next">
            <RightArrow />
          </ArrowLink> */}
        </BannerWrap>
        <AnimatePresence exitBeforeEnter>
          <FadeWrap
            variants={fadeVariants}
            initial="enter"
            animate="animate"
            exit="exit"
            transition={{
              duration: 0.7,
            }}
            key={data.title}
          >
            <TitleWrap>
              <Title>{data.title}</Title>
              <Subtitle>{data.subtitle}</Subtitle>
              <DescText>{data.description}</DescText>
            </TitleWrap>
            <ConstrainWrap>
              <BgImg src={curveBg} alt="" />
              <SectionWrap
                wrap="wrap"
                justifyContent="center"
                alignItems="flex-start"
              >
                <DescSection>
                  <SectionHeading>MY ROLE</SectionHeading>
                  {Array.isArray(data.role) ? (
                    <SectionList>
                      {data.role.map((val) => (
                        <SectionListItem key={val}>{val}</SectionListItem>
                      ))}
                    </SectionList>
                  ) : (
                    <SectionText>{data.role}</SectionText>
                  )}
                </DescSection>
                <DescSection>
                  <SectionHeading>PROJECT DURATION</SectionHeading>
                  <SectionText>{data.duration}</SectionText>
                </DescSection>
                <DescSection>
                  <SectionHeading>TOOLS USED</SectionHeading>
                  <SectionList>
                    {data.tools.map((val) => (
                      <SectionListItem key={val}>{val}</SectionListItem>
                    ))}
                  </SectionList>
                </DescSection>
                <DescSection>
                  <SectionHeading>MY RESPONSIBILITIES</SectionHeading>
                  <SectionList>
                    {data.responsibilities.map((val) => (
                      <SectionListItem key={val}>{val}</SectionListItem>
                    ))}
                  </SectionList>
                </DescSection>
                <DescSection>
                  <SectionHeading>THE PROBLEM</SectionHeading>
                  <SectionText>{data.problem}</SectionText>
                </DescSection>
                <DescSection>
                  <SectionHeading>THE GOAL</SectionHeading>
                  <SectionText>{data.goal}</SectionText>
                </DescSection>
              </SectionWrap>
            </ConstrainWrap>
          </FadeWrap>
        </AnimatePresence>
      </StyledFlexContainer>
    </StyledPage>
  );
};

export default Description;
