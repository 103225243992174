import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import Page from "../components/Page";
import { breakpoints, colors, fonts, PageTitle } from "../constants";
import { useInView } from "react-intersection-observer";
import ProjectLinks from "../components/ProjectLinks";
import { AnimatePresence, motion } from "framer-motion";
import ProjectPhotos from "../components/ProjectPhotos";
import photos from "../photography";
import funsies from "../Funsies";
import curveBg from "../assets/curveBackground.png";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

`;

const StyledPage = styled(Page)`
  /* min-height: 100vh; */
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0px 24px;

  /* &:after {
    content: "";
    position: absolute;
    height: 160px;
    left: -10%;
    right: -10%;
    border-radius: 50%;
    bottom: -25px;
    background: #fff;
  }
  &:before {
    content: "";
    position: absolute;
    height: 160px;
    left: -10%;
    right: -10%;
    border-radius: 50%;
    top: -25px;
    background: #fff;
  } */
  @media (max-width: ${breakpoints.xs}) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const BgImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
`;

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin: 0px auto;
  opacity: 0;
  animation: 0.8s ${(props) => props.inView && fadeIn} ease-in forwards;
  animation-delay: 0.2s;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 120px 0px;
  /* border: 2px solid red; */
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Title = styled(PageTitle)`
  @media (max-width: 900px) {
    order: 1;
    padding-bottom: 12px;
  }
`;
const NavTabs = styled.div`
  border: 2px solid black;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.bg};
  display: flex;
  position: relative;
  transform: translateY(6px);
  overflow: hidden;
  /* border: 1px solid black; */
  @media (max-width: 900px) {
    order: 2;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 160px;
    z-index: 1;
    background-color: ${colors.secondary};
    /* opacity: 0.75; */
    transition: transform 0.5s ease;
    @media (max-width: ${breakpoints.xs}) {
      width: 120px;
    }

    ${({ tabState, activeTab }) => {
      if (activeTab === tabState.ux) {
        return `transform: translateX(0px);
        `;
      } else if (activeTab === tabState.photography) {
        return `transform: translateX(160px);
       @media(max-width: ${breakpoints.xs}) {
         transform: translateX(120px);
       } 
        `;
      } else if (activeTab === tabState.funsies) {
        return `transform: translateX(320px);
        @media(max-width: ${breakpoints.xs}) {
         transform: translateX(240px);
       } 
        `;
      }
    }}
  }
`;
const Tab = styled.div`
  &:first-child {
    border-radius: 20px 0px 0px 20px;
    border: none;
  }
  &:last-child {
    border-radius: 0px 20px 20px 0px;
    border: none;
  }
  overflow: hidden;
  font-family: ${fonts.secondary};
  font-weight: bold;
  z-index: 5;
  isolation: isolate;
  padding: 5px;
  width: 160px;
  text-align: center;
  background-color: transparent;
  position: relative;
  cursor: pointer;
  border-left: 2px solid black;
  border-right: 2px solid black;
  color: black;
  transition: color 0.2s ease;
  transition-delay: 0.1s;
  @media (max-width: ${breakpoints.xs}) {
    width: 120px;
  }
`;
const ProjectWrap = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 64px;
  flex-wrap: wrap;
  padding: 180px 0px;
  overflow: hidden;
  @media (max-width: ${breakpoints.sm}) {
    padding: 80px 0px 0px;
  }
`;

const tabState = {
  ux: "ux",
  photography: "photography",
  funsies: "funsies",
};

const Projects = ({ workRef }) => {
  const [activeTab, setActiveTab] = useState(tabState.ux);
  const [animRef, animInView] = useInView({ triggerOnce: true });

  return (
    <StyledPage>
      <BgImg src={curveBg} alt="" />
      <Wrapper ref={workRef} id="work" inView={animInView}>
        <Header>
          <Title>WORK</Title>
          <NavTabs activeTab={activeTab} tabState={tabState}>
            <Tab
              onClick={() => setActiveTab(tabState.ux)}
              isActive={activeTab === tabState.ux}
            >
              UX/UI
            </Tab>
            <Tab
              onClick={() => setActiveTab(tabState.photography)}
              isActive={activeTab === tabState.photography}
            >
              Photography
            </Tab>
            <Tab
              onClick={() => setActiveTab(tabState.funsies)}
              isActive={activeTab === tabState.funsies}
            >
              Funsies
            </Tab>
          </NavTabs>
        </Header>
        <AnimatePresence exitBeforeEnter>
          {activeTab === tabState.ux ? (
            <ProjectWrap
              style={{ gap: "32px" }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              ref={animRef}
              key={tabState.ux}
            >
              <ProjectLinks />
            </ProjectWrap>
          ) : activeTab === tabState.photography ? (
            <ProjectWrap
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              ref={animRef}
              key={tabState.photography}
            >
              <ProjectPhotos photos={photos} />
            </ProjectWrap>
          ) : (
            <ProjectWrap
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              ref={animRef}
              key={tabState.funsies}
            >
              <ProjectPhotos photos={funsies} />
            </ProjectWrap>
          )}
        </AnimatePresence>
        {/* <Carousel ref={animRef} inView={animInView}></Carousel> */}
      </Wrapper>
    </StyledPage>
  );
};

export default React.memo(Projects);
