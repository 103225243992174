import About from "./layout/About";
import Projects from "./layout/Projects";
import Contact from "./layout/Contact";
import Home from "./layout/Home";
import BackToTop from "./components/BackToTop";
import Navbar from "./components/Navbar";
import { useLocation } from "react-router-dom";
import { useRef } from "react";

const Main = () => {
  const workRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  return (
    <>
      <Navbar workRef={workRef} aboutRef={aboutRef} contactRef={contactRef} />
      <div style={{ overflowX: "hidden" }}>
        <Home />
        <Projects workRef={workRef} />
        <About aboutRef={aboutRef} />
        <Contact contactRef={contactRef} />
        <BackToTop />
      </div>
    </>
  );
};

export default Main;
