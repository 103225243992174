import React, { useEffect } from "react";
import Description from "./Description";
import Pictures from "./Pictures";
import Contact from "../Contact";
import BackPointer from "../../components/BackPointer";
import BackToTop from "../../components/BackToTop";

const Project = ({ projectData }) => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Description data={projectData.general} />
      <Pictures data={projectData} />
      <Contact />
      <BackPointer />
      <BackToTop />
    </div>
  );
};

export default Project;
