import React from "react";
import styled from "styled-components";
import { colors } from "../constants";

const Wrapper = styled.svg`
  display: inline;
  transform: translateY(2px);
`;
function Arrow() {
  return (
    <Wrapper
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      ariaHidden="true"
      viewBox="0 0 16 16"
    >
      <path
        fill={colors.primaryHighlight}
        fillRule="evenodd"
        d="M1 8a.5.5 0 01.5-.5h11.793l-3.147-3.146a.5.5 0 01.708-.708l4 4a.5.5 0 010 .708l-4 4a.5.5 0 01-.708-.708L13.293 8.5H1.5A.5.5 0 011 8z"
      ></path>
    </Wrapper>
  );
}

export default Arrow;
