import React from "react";
import { useInView } from "react-intersection-observer";
import styled, { css, keyframes } from "styled-components";
import AlissaPic from "../assets/alissaAbout.jpg";
import Arrow from "../components/Arrow";
import Page from "../components/Page";
import Sparkles from "../components/Sparkles";
import {
  BigText,
  breakpoints,
  colors,
  Heading,
  PageTitle,
  Text,
} from "../constants";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

`;
const StyledPage = styled(Page)`
  /* padding-bottom: 10%; */
  height: initial;
  padding-bottom: calc(${(props) => props.theme.linePosition.bottom} + 80px);
  position: relative;
  padding-bottom: 40px;
  @media (max-width: ${breakpoints.lg}) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  gap: 48px;
  padding: 200px 0px;
  align-items: stretch;
  opacity: 0;
  animation: 0.8s ${(props) => props.inView && fadeIn} ease-in forwards;
  position: relative;
  isolation: isolate;
  max-width: 1440px;
  margin: 0px auto;

  animation-delay: 0.2s;
  @media (max-width: ${breakpoints.lg}) {
    align-items: flex-start;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
  /* border: 2px solid green; */
`;
export const Bubble = styled.div`
  background-color: ${colors.secondaryHighlight};
  border-radius: 50%;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  position: absolute;
  z-index: -1;

  ${(props) =>
    props.position &&
    css`
      ${props.position}
    `}
`;
const StyledPageTitle = styled(PageTitle)`
  display: block;

  opacity: 0;
  animation: 0.8s ${(props) => props.inView && fadeIn} ease-in forwards;

  animation-delay: 0.2s;
`;

const AboutWrapper = styled.div`
  border-radius: 32px;
  background-color: white;
  padding: 48px;
  flex: 1;

  /* padding-bottom: 64px; */
  padding-right: 24px;

  @media (max-width: 900px) {
    order: 2;
    padding-right: 48px;
    padding-top: 0px;
  }
`;
const StyledHeading = styled(Heading)`
  color: ${colors.primaryHighlight};
  padding-bottom: 4px;
  ${(props) =>
    props.isAboutRight &&
    `
    display: none;
    @media (max-width: 1400px){
      display: block;
    }
    @media (max-width: 900px) {
      display: none;
    }
  `};
  ${(props) =>
    props.isAboutLeft &&
    `
    @media(max-width: 1400px) {
      display: none;
    }
    @media (max-width: 900px) {
      display: block;
    }
  `}
`;
const StyledBigText = styled(BigText)`
  padding-bottom: 12px;
`;
const StyledText = styled(Text)`
  /* margin-bottom: 24px; */
  margin-bottom: 24px;
  /* font-size: 1rem; */
`;

const Image = styled.img`
  animation: 0.8s ${(props) => props.inView && fadeIn} ease-in forwards;
  animation-delay: 0.2s;
  /* padding: 200px 0px; */
  border: 6px solid ${colors.primaryHighlight};
  border-radius: 32px;
  /* padding-left: 24px; */
  /* flex: 1; */
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
  @media (max-width: 1400px) {
    margin-bottom: 16px;
    padding-left: 0px;
    height: auto;
    margin-left: 0px;
  }
  @media (max-width: 900px) {
    width: 400px;
    height: auto;
  }
`;
const ImageWrap = styled.div`
  flex: 1;
  /* @media (max-width: 1400px) {
    height: 700px;
  } */
  @media (max-width: 900px) {
    order: 1;
    margin-left: auto;
    margin-right: auto;
    /* border: 2px solid red; */
  }
`;
const ImageTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.bg};
  border-radius: 32px;
  padding: 48px;
  display: none;
  @media (max-width: 1400px) {
    display: block;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const About = ({ aboutRef }) => {
  const [titleRef, titleInView] = useInView({ triggerOnce: true });

  return (
    <StyledPage>
      <Wrapper ref={aboutRef} id="about" inView={titleInView}>
        <Bubble
          width={"600px"}
          height={"600px"}
          position={"bottom: 0px; right: -80px;"}
        />
        <Bubble
          width={"240px"}
          height={"240px"}
          position={"top: 60px; left: -120px;"}
        />
        <Bubble
          width="80px"
          height="80px"
          position={"bottom: 40px; right: 600px;"}
        />
        <AboutWrapper ref={titleRef}>
          <StyledPageTitle inView={titleInView}>ABOUT</StyledPageTitle>
          <StyledBigText>
            Ciao! I’m Alissa. My mission is to design intuitive and enjoyable
            experiences both online and IRL for{" "}
            <strong style={{ color: "#D29786" }}>everyone.</strong>
          </StyledBigText>
          <StyledHeading>My Background.</StyledHeading>
          <StyledText>
            Originally from all over the Midwest (Grand Rapids, MI <Arrow />{" "}
            Chicago, IL <Arrow /> Minneapolis, MN), I now reside in the magical
            city of Seattle, WA.
            <br />
            <span style={{ display: "inline-block", height: "30px" }}></span>
            As a multipotentialite and a nomad, it has been a struggle
            determining where exactly, I, as a person with a multitude of
            different interests (and history to prove it) would fit comfortably
            in the work force for the long haul. In the field of UX Design, I
            finally found a home.
            <br />
            <span style={{ display: "inline-block", height: "30px" }}></span>
            With over a decade of experience in customer service and a degree in
            psychology, my focus has always been on understanding people and
            trying to make them feel comfortable in this complicated world. It
            wasn’t until recently that I realized that I possessed a passion for
            design that matched the excitment I feel about psychology and
            people. Lucky for me, my two main interests are not mutually
            exclusive!
            <br />
            <span style={{ display: "inline-block", height: "30px" }}></span>
            Throughout my UX journey I learned that I have a knack for
            simplifying the complex, improving and creating processes,
            identifying pain points, solving problems, and crafting seamless
            live and virtual experiences, all while keeping things{" "}
            <Sparkles>stylish.</Sparkles>
          </StyledText>
          <StyledHeading isAboutLeft>My Toolset.</StyledHeading>
          <StyledText isAboutLeft>
            Figma. Sketch. Adobe XD. Illustrator. Lightroom. InDesign. After
            Effects. Photoshop. Procreate. Mural. Maze. Miro. InVision. Trello.
            HTML. CSS. Javascript. SketchUp.
          </StyledText>
          <StyledHeading isAboutLeft>In My Off Time.</StyledHeading>
          <StyledText isAboutLeft>
            Traveling the world. Snuggling my two pups. Obsessively looking at
            property on Zillow. Interior Designing. Jammin’ to ~mostly~ hiphop.
            Watching the latest A24 flick. Reading a nonfiction. Baking a cake.
            Enjoying a nice glass of red wine.
          </StyledText>
          <StyledHeading isAboutLeft>My Passions.</StyledHeading>
          <StyledText isAboutLeft>
            Design (Physical and Virtual). Social sciences. Sustainability &
            Environmentalism. Animal justice. Photography. Realty. Music. Film.
            Experiencing different cultures.
          </StyledText>
        </AboutWrapper>
        <ImageWrap>
          <Image
            src={AlissaPic}
            alt="Me and my dog Koia at the International Rose Garden in Portland, Oregon"
          />
          <ImageTextWrap>
            <StyledHeading isAboutRight>My Toolset.</StyledHeading>
            <StyledText isAboutRight style={{ paddingBottom: "0px" }}>
              Figma. Sketch. Adobe XD. Illustrator. Lightroom. InDesign.
              Photoshop. Procreate. Mural. Maze. Miro. InVision. Basic HTML/CSS.
              Vanilla Javascript.
            </StyledText>
            <br />
            <StyledHeading isAboutRight>In My Off Time.</StyledHeading>
            <StyledText isAboutRight>
              Traveling the world. Snuggling my two pups. Obsessively looking at
              property on Zillow. Interior Designing. Jammin’ to ~mostly~
              hiphop. Watching the latest A24 flick. Reading a nonfiction.
              Baking a cake. Enjoying a nice glass of red wine.
            </StyledText>
            <StyledHeading isAboutRight>My Passions.</StyledHeading>
            <StyledText isAboutRight>
              Design (Physical and Virtual). Social sciences. Sustainability &
              Environmentalism. Animal justice. Photography. Realty. Music.
              Film. Experiencing different cultures.
            </StyledText>
          </ImageTextWrap>
        </ImageWrap>
      </Wrapper>
    </StyledPage>
  );
};

export default React.memo(About);
