import React, { useState } from "react";
import styled from "styled-components";
import { fonts } from "../constants";

const Image = styled.img`
  height: 100%;
  width: auto;
  position: relative;
  cursor: pointer;

  &:last-child {
    padding-right: 0px;
  }
`;

const Photo = ({ src, handleClick }) => {
  return <Image src={src} alt="" onClick={() => handleClick(src)} />;
};

export default Photo;
