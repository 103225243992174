import React from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import { breakpoints } from "../../constants";

const BannerImg = styled(motion.img)`
  display: block;
  position: absolute;
  top: 0px;
  /* @media (max-width: ${breakpoints.sm}) {
    display: none;
  } */
`;
const variants = {
  enter: (direction) => {
    return {
      x: direction === "next" ? "100%" : "-100%",
    };
  },
  center: { x: "0%" },
  exit: (direction) => {
    return {
      x: direction === "next" ? "-100%" : "100%",
    };
  },
};
const Banner = ({ activeImg, direction }) => {
  return (
    <AnimatePresence initial={false} custom={direction}>
      <BannerImg
        variants={variants}
        custom={direction}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: 1 }}
        key={activeImg}
        src={activeImg}
        alt=""
      />
    </AnimatePresence>
  );
};

export default Banner;
