import React from "react";

function AlissaToon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="425"
      height="554"
      fill="none"
      viewBox="0 0 425 554"
    >
      <ellipse
        cx="212.5"
        cy="467.367"
        fill="#F2E9DF"
        rx="210.68"
        ry="80.96"
        transform="rotate(1.329 212.5 467.367)"
      ></ellipse>
      <path
        fill="#fff"
        d="M307.014 172.637l2.69-.707 2.406-5.947 4.248-2.831 2.831-2.832 6.088-3.256 3.823-6.088 4.672-2.265 2.406.566.567 3.115-4.106 7.928v1.841h10.193l10.76 2.973 5.38 3.822.849 5.947 3.398 2.548-.566 2.407-2.832 2.548 1.133 3.115-7.362.991-13.45-2.549-14.299 3.398-1.84 1.274-4.247.991.141 3.115-2.548 1.133-3.256-1.274-7.079-13.875-.708-2.548.708-3.54z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.416"
        d="M306.164 177.734c-.424-1.51-.113-5.408 3.398-6.087.265-.936.497-1.731.706-2.407m4.391-4.106c0 7.928 1.274 15.29 10.193 22.227m-10.193-22.227c-2.602.496-2.927-.635-4.391 4.106m4.391-4.106c.566-1.557 3.398-5.38 10.193-8.211 3.681-10.194 20.246-14.016 6.938 4.955 6.984-1.038 28.881 1.84 28.173 12.034m-35.111 13.449c1.586 2.379-3.02 2.03-5.521 1.558.566.991 1.359 3.284 0 4.53-1.359 1.246-4.247.047-5.238-.849m10.759-5.239c3.115-.094 10.477-.877 15.007-3.256 0-.849 11.326 3.115 17.98 2.407 11.751-2.973-11.75-8.778-12.883-8.778-.51 0-.849-2.265-.708-2.973m-33.98-5.521c-.188 2.076.03 7.418 2.409 12.175m31.571-15.573c.378 1.038.991 2.831.425 4.53 8.117.284 27.041 5.947 14.751 12.176m-40.801-10.901c3.964-.897 13.167-.935 18.263 6.087"
      ></path>
      <path
        fill="#fff"
        d="M77.52 219.924l2.407.708 7.645 15.007h3.964l2.548 1.698 2.832-3.539 2.831 1.841 8.637 19.82-2.124 2.407-5.805-.425-1.699 3.115-6.229-1.557-16.423 1.557-17.697 5.521h-4.671l-1.7-3.681-3.68-3.397 2.265-4.956v-4.105l3.822-4.248 10.618-5.238 12.46-3.115-2.124-6.087v-7.645l2.123-3.681z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.416"
        d="M100.739 238.753c-.142-1.604-.992-4.813-3.257-4.813-2.831 0-2.123 3.398-3.822 3.398-1.7 0-1.133-2.549-4.53-1.274a1.585 1.585 0 00-.567.395m19.254 18.717c-.113 4.304-4.766 3.115-7.078 1.982-2.266 3.681-.708 3.256-3.257 3.256-13.624-2.27-12-20.798-8.919-23.955m0 0c-1.982-2.869-6.428-10.051-8.353-15.827-6.654-3.964-6.795 10.618-2.407 16.706-13.166 1.415-30.438 10.193-27.04 15.431-1.047 1.343-1.719 2.517-2.102 3.54m3.659 6.088c-.52 1.746-.142 4.983 5.521 3.964 5.663-1.02 14.44-4.389 18.122-5.947 5.333-.566 16.168-1.529 16.847-.849m-40.49 2.832c-2.15-.34-5.186-2.017-3.66-6.088m3.66 6.088c2.265-3.162 9.287-9.656 19.254-10.335.566-1.36.236-3.304 0-4.106m-1.416-5.663v3.398c0 .566-11.99.566-21.497 10.618m26.028-4.247c1.132-2.879 4.615-8.58 9.485-8.353"
      ></path>
      <path
        fill="#DEA797"
        d="M148.166 137.669l-2.548-10.477V106.522l.849-13.591 1.699-5.522 3.54-4.53 5.946-7.079 8.778-4.672 20.528-6.37 9.91-1.275 11.468 1.274 11.326 5.239 7.787 8.21 7.22 15.716 5.521 9.202 2.69 7.787.567 13.733 1.274 5.379-1.274 13.309-1.275 4.813-3.114 4.955-4.389 4.531-8.778 6.937-8.636 3.681-.566 4.106v5.238l2.407 5.13 4.247-.741-4.247 7.362-4.248 5.238-3.822 3.115-4.106 1.557-5.663-.991-5.521-4.955-5.38-9.203 2.548.567 1.982-5.663-2.548-5.522-7.645.85-6.088-.85-5.38-1.132-4.389-2.266-6.512-5.521-4.106-4.814-4.53-7.503-3.256-6.937-2.266-7.645z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2.265"
        d="M172.376 103.266c-5.758-1.746-17.188-2.747-16.848 7.22m43.605-15.148c3.823-4.2 13.592-14.583 18.264-2.266"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.982"
        d="M191.205 122.52c-2.264 9.529.388 17.562 12.14 8.501M163.113 132.217a17.958 17.958 0 011.88-1.949m19.275-5.058a135.65 135.65 0 00-2.354-.462m19.467-5.554c2.802-3.423 10.795-9.381 20.346-5.824m-56.734 16.898c-2.408-5.832-5.145-18.089 3.168-20.458 8.314-2.369 12.633 8.971 13.753 14.938m-16.921 5.52c3.42-3.08 9.596-6.689 16.921-5.52"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.982"
        d="M203.805 116.857c-2.531-6.082-5.534-18.92 2.69-20.387 9.344-1.982 12.596 9.608 13.377 16.134"
      ></path>
      <path
        fill="#000"
        d="M163.406 113.61l4.079-3.866 5.973.018 3.853 4.216 2.46 5.367 1.487 5.125-7.691.098-5.587 3.291-4.574-14.249zM202.037 100.681l1.844-2.58 2.235-1.287 2.96-.905 3.013.924 3.853 4.215 2.46 5.368 1.488 5.125-7.692.097-5.587 3.292-4.574-14.249z"
      ></path>
      <path
        stroke="#FFF5ED"
        strokeLinecap="round"
        strokeWidth="1.982"
        d="M208.64 104.686c-1.258.73-3.872 2.919-4.273 5.839M169.808 117.654c-1.257.73-3.871 2.92-4.272 5.839"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.982"
        d="M186.306 144.274c6.618 4.218 21.589 8.846 30.269-9.728m-4.223-2.075c1.374 1.415 5.169 3.558 9.359.814m-40.249 12.351c2.137.105 5.189-2.474 5.125-5.814"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.982"
        d="M185.803 143.213c1.182 5.243 6.364 15.333 17.636 13.75 11.273-1.582 9.385-14.451 11.939-19.821"
      ></path>
      <path
        fill="#000"
        d="M188.409 149.58l-.931-3.991 6.87 2.133 12.697-1.421 6.747-5.693-.366 2.823-1.289 6.621-8.995 1.421-4.514 5.493-5.65-2.775-4.569-4.611z"
      ></path>
      <path
        fill="#FF5247"
        d="M210.68 151.477c-.496 1.033-1.663 2.923-3.854 3.948-2.191 1.026-5.742 1.749-6.208.754-.07-.149 1.755-2.774 4.522-4.355 2.124-.859 2.51-.611 5.54-.347z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.982"
        d="M199.811 156.67c.651-2.234 3.247-4.779 5.35-5.519 1.69-.594 3.519-.83 6.259-.364"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="0.849"
        d="M203.574 160.69c2.365.308 7.454-.006 8.891-3.732"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.416"
        d="M217.217 101.715c2.049.154 5.23-.225 5.07-4.277m-60.995 23.239c-1.731 1.374-5.436 3.171-6.414-.631M161.517 118.189c-1.241.396-3.771.513-3.97-2.182M216.345 99.509c1.321-.359 3.297-1.424 2.828-3.888"
      ></path>
      <path
        fill="#FFDA7A"
        d="M155.953 203.784s5.758-4.709 8.778-8.353c2.749-3.317 3.653-7.295 7.078-9.91 2.143-1.636 2.617-1.64 5.239-2.265 2.904-.693 4.675-.303 7.645 0 2.405.245 5.663.566 5.663.566s6.776 14.547 15.148 15.29c9.815.871 18.122-17.555 18.122-17.555s4.096-1.228 6.796-1.274c2.971-.051 4.851-.068 7.503 1.274 2.066 1.045 2.726 2.373 4.53 3.822 2.216 1.78 3.408 2.687 5.947 3.965 2.863 1.441 7.786 2.548 7.786 2.548l-10.335 18.263-15.431-3.539s4.322 6.815 5.379 11.751c.708 3.304 1.277 5.331.567 8.636-.521 2.42-1.126 3.778-2.549 5.804-1.1 1.569-1.931 2.306-3.397 3.54-2.352 1.977-6.796 3.964-6.796 3.964l-.849 10.618-51.251-7.504.567-4.247s-2.664-1.285-3.823-2.265c-1.045-.884-1.689-1.383-2.407-2.548-.935-1.521-1.1-2.613-1.274-4.389-.157-1.6.283-4.106.283-4.106v-2.832l-5.38 4.389-3.539-23.643z"
      ></path>
      <path
        stroke="#F7B917"
        strokeLinecap="round"
        strokeWidth="1.982"
        d="M172.517 239.461c2.69.567 3.398.85 8.07-.566m43.322 1.274c-3.162.33-5.238.566-7.928-2.548"
      ></path>
      <path
        stroke="#000"
        strokeWidth="1.416"
        d="M165.155 223.322c1.18-2.69 4.106-9.061 7.787-10.902"
      ></path>
      <path
        fill="#DEA797"
        d="M99.182 240.027l12.458-5.238 14.158-7.362 15.856-10.901 14.158-11.751 3.539 23.218-14.016 10.194-9.485 5.38-12.317 5.804-8.92 3.115-9.769 1.274-4.389-1.982-2.406-3.115-.708-5.096 1.84-3.54zM306.213 177.461l-2.739 3.671-5.238 3.822-8.919 4.248-9.202 2.407-9.628 1.84h-8.069l-6.371-.777-9.769 17.058 13.591 1.133 12.742-1.133 13.308-2.831 12.317-5.097 9.792-5.097 5.498-4.033-2.973-7.718-4.34-7.493zM171.385 243.001l50.825 7.503-.708 8.353-52.807-8.353 2.69-7.503z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.416"
        d="M170.96 186.087c-17.98 28.882-56.347 47.853-71.779 53.799-1.84 1.133-4.813 11.326 5.522 14.016 11.184.849 38.508-10.42 60.028-30.58 2.265-2.123-5.522 11.467 7.645 15.998.566-1.275-2.265 10.618-4.106 10.759-11.326 3.823-13.874 6.938-21.236 14.299-7.362 7.362-22.256 32.081-7.079 81.123 2.029 6.843 5.861 21.746 4.955 26.616-1.132 6.088-1.274 41.482 15.007 64.7 4.672 7.787 7.079 7.787 11.892 14.016 3.851 4.984 4.72-7.267 4.672-14.016.236-12.695-1.283-37.8 0-42.614 1.662-6.229 6.513-9.344 7.787-26.191 0-11.1.425-13.167.85-15.715l9.344-32.987 12.317 32.987c1.227 5.615 5.634 19.282 13.449 29.023 9.769 12.175 14.441 23.501 16.14 28.739 2.69 5.947 6.654 29.306 8.07 32.704 5.096 10.194 10.335.283 12.317-5.946 2.878-6.937 8.749-24.691 9.202-40.207-1.274-20.387-12.317-29.023-14.157-34.12-1.473-4.077-1.841-11.137-1.841-14.158.755-5.71.219-20.349-3.115-34.544-4.388-18.688-7.362-29.872-14.44-41.764-5.663-9.514-6.796-10.194-10.194-13.167-.476-.417 1.133-18.971 2.124-18.971 18.829-8.211 11.671-23.904 6.512-32.846-2.123-3.68 35.111 18.405 82.964-14.016.835-.566-7.381-15.851-7.929-15.148-9.485 12.175-47.994 25.2-65.974 4.672-2.407-2.548-9.032-3.851-19.112 0-5.21.679-4.248-13.733-3.54-13.733 24.068-10.476 25.767-23.785 25.767-23.785 5.238-19.82-3.964-44.171-15.007-65.407-7.373-14.18-25.012-15.196-35.394-16.14-24.351 4.672-47.228 13.473-47.994 29.73-2.548 54.083 13.308 69.939 26.333 78.009 12.175 4.813 11.562 2.312 21.661 2.548 1.274.897 3.058 5.833 0 10.477-15.29-2.492-20.811.189-21.661 1.84z"
      ></path>
      <path
        stroke="#000"
        strokeWidth="1.416"
        d="M156.094 204.633l3.54 23.36M256.047 192.316l-10.052 17.414M190.356 183.964c4.908 11.656 18.263 28.598 33.411-2.407"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.416"
        d="M171.668 243.142l50.967 7.503"
      ></path>
      <path
        fill="#FFF5ED"
        d="M163.886 122.905c-.824-4.634.171-7.523.171-7.523l3.696 11.601-2.294 1.624s-.748-1.069-1.573-5.702zM202.565 109.374c-1.046-5.246-.157-7.862-.157-7.862l3.894 12.475-1.97 1.229s-.721-.595-1.767-5.842z"
      ></path>
      <path
        fill="#342727"
        d="M135.141 93.984l3.681-15.29 11.751-8.778 13.308-8.07 22.086-5.38 12.742-1.699 12.317-1.699 6.087 3.398 10.052 12.114 8.07 11.105 5.097 14.299v10.476l-8.353-13.874-8.778-10.901s-4.302-4.244-7.503-6.371c-1.754-1.166-2.679-2.012-4.672-2.69-2.058-.701-3.359-.928-5.522-.708-4.955.504-5.946.504-10.618 1.778-4.558 1.243-6.512.708-12.034 6.23-5.521 5.521-14.016 16.484-19.396 15.856-5.38-.628-4.247-9.707-3.397-9.707.849 0 1.557 6.45 6.512 3.195 4.955-3.257-1.624-4.53-2.832-4.53-2.689 0-3.991.048-6.512.424-1.731.258-2.779.224-4.389.911-2.786 1.19-3.625 2.811-5.097 5.46-2.123 3.822-1.533 11.211-2.123 17.13-.635 6.371 2.365 12.782.991 22.086-.287 1.942 0 3.823 0 3.823l-5.238-5.805-7.362-12.113-3.115-11.893 4.247-8.777z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.416"
        d="M146.751 92.081c-1.807 7.95 1.84 48.985-1.699 40.916-2.187-6.052-10.738-15.857-13.309-24.776-2.406-8.353 4.367-13.343 5.097-22.228.85-10.334 6.127-10.291 13.249-15.573 5.271-3.909 12.188-7.293 21.154-9.91 9.129-2.664 16.489-4.124 22.511-4.684 6.855-.637 14.949-3.218 18.971-1.97 6.964 2.162 10.49 10.156 14.157 14.299 4.069 4.596 7.262 8.864 9.769 14.44 4.447 9.895 3.904 20.606 5.097 25.626 3.397 14.299-3.518-7.995-7.929-14.17-6.234-8.726-17.637-24.045-26.757-23.63-15.573.707-19.96 4.154-25.201 8.777-2.407 2.124-10.618 12.883-17.414 14.582-5.521.425-6.795-7.079-4.388-9.202.127-.113 1.715 3.285 3.822 3.397 2.388.128 4.814-2.123 4.531-3.256-.779-3.114-18.83-5.096-21.661 7.362z"
      ></path>
      <path
        fill="#342727"
        stroke="#000"
        strokeWidth="1.416"
        d="M169.803 60.53h0l-.045.01-.045.018c-4.047 1.566-9.627 3.547-14.923 4.655-2.649.553-5.201.883-7.442.842-2.203-.04-4.035-.439-5.361-1.27-4.118-4.895-5.286-8.366-5.722-11.026-.147-.899-.21-1.679-.27-2.432-.033-.413-.065-.819-.111-1.232-.124-1.125-.342-2.212-.989-3.264-1.655-2.692-1.533-5.592-.619-8.34.917-2.76 2.612-5.292 3.985-7.15.898-1.213 2.251-2.07 3.905-2.683 1.653-.614 3.559-.967 5.502-1.202 1.351-.164 2.688-.268 3.961-.367.565-.044 1.118-.086 1.653-.133 1.695-.148 3.261-.336 4.342-.755.469-.181 1.016-.496 1.602-.854.273-.167.564-.35.869-.542l.084-.052c.337-.212.693-.435 1.067-.664 1.5-.915 3.292-1.917 5.331-2.629 4.015-1.402 9.014-1.695 14.626 2.177 1.554 2.353 3.28 4.01 4.917 5.361.667.55 1.325 1.054 1.944 1.528l.439.336c.749.577 1.427 1.114 2.024 1.673 1.18 1.105 2.011 2.266 2.345 3.883.34 1.641.187 3.838-.787 7.038-1.898 6.24-7.388 10.51-12.538 13.243a48.036 48.036 0 01-8.956 3.618c-.256.073-.455.127-.589.162l-.151.039-.037.01-.009.001h-.002 0z"
      ></path>
      <path
        fill="#FF7B7B"
        stroke="#000"
        strokeWidth="0.849"
        d="M170.444 60.612l-.019.004-.019.006c-3.13.943-5.709 2.243-7.773 3.283-.691.349-1.325.668-1.902.935-1.175.544-2.07.849-2.769.83a1.573 1.573 0 01-.864-.258c-.247-.165-.486-.43-.709-.84-.514-1.344-.585-2.417-.329-3.309.258-.896.865-1.67 1.823-2.381 1.94-1.441 5.181-2.522 9.343-3.775 1.527-.46 2.953-.945 4.266-1.392.476-.162.936-.318 1.381-.467 1.684-.562 3.138-1.005 4.38-1.194 1.243-.19 2.21-.113 2.957.298.737.405 1.34 1.18 1.758 2.569.239.795.195 1.37-.065 1.837-.27.482-.814.926-1.744 1.354-1.872.861-5.034 1.544-9.715 2.5z"
      ></path>
      <path
        fill="#DEA797"
        d="M140.205 132.912l.599-2.322 1.387-1.561 2.436.711 1.764 3.172 4.734 6.031 1.289 7.787-1.841 1.132-.686 1.62-1.421.073-2.075-1.403-4.111-7.25-2.076-3.8.001-4.19zM247.269 117.848l-1.699 3.681-2.042-.944-3.924-9.391-1.82-4.672 1.82-8.192 1.436-1.01 1.671 1.01 2.125 1.935 3.434 8.87-1.001 8.713z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.416"
        d="M147.372 135.204c-1.542-3.972-5.139-10.232-7.193-3.495-2.054 6.738 8.41 23.435 10.682 15.786M238.916 105.531c-.976-4.134-.165-11.3 5.097-6.748 5.262 4.553 4.943 29.838-.85 20.339"
      ></path>
      <path
        fill="#DEA797"
        d="M232.545 114.875l12.459-3.681 1.982 3.823-.142 1.982-.424 1.698-.991 1.558-.425-4.247-1.416-.708-1.416 4.955-.708 2.407v7.361l2.124 8.212-.566 1.84-10.477-25.2z"
      ></path>
      <path
        fill="#000"
        d="M247.127 114.875l1.157.425-.166 2.69-1.274-.284v-.424l.142-1.699.141-.708z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="2.265"
        d="M155.528 110.911c1.227-3.54 6.314-9.939 16.848-7.221m44.596-10.476c-.991-3.492-5.833-7.872-17.272 2.548"
      ></path>
      <path
        fill="#000"
        d="M144.768 147.437l.849-1.274 1.275 1.415-.708 1.275-1.416-1.416z"
      ></path>
      <path
        fill="#DEA797"
        d="M148.166 144.464l.283 2.548-1.133.991-1.132-1.132c-.988-.989-.849-1.133-.849-1.133l2.831-1.274z"
      ></path>
      <path
        fill="#F7B917"
        d="M232.12 205.908l15.574-.567-2.124 3.823-6.795-1.416-6.655-1.84zM172.517 211.854l-1.416.991-1.698 1.487-1.558 2.194-1.132 1.982-1.133 2.124-.849 1.84s.592-4.752 2.406-7.503c1.723-2.612 5.38-3.115 5.38-3.115z"
      ></path>
      <path
        fill="#fff"
        d="M161.333 439.932l2.407 2.69 3.256 1.982 3.398.566 3.539-.991 2.407-2.973.849-3.964 4.106 1.699 2.407 2.265v3.398l-2.407 3.681v4.53h1.274l6.654 1.133 5.522 2.548 2.831 2.407 1.982 3.115 1.841 3.114 1.132 5.805v5.663l-5.521 3.822-6.371 2.973h-15.29l-11.609-2.406-8.636-3.681v-2.124l1.557-12.459 2.973-13.874h.991l-2.973-2.124v-3.256l1.84-2.265 1.841-1.274z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="0.849"
        d="M161.899 439.082c-3.195 1.901-7.299 6.056-2.13 9.344m3.404 1.558c-1.423-.486-2.543-1.01-3.404-1.558m17.279-11.184c3.938.835 6.962 3.217 7.078 5.946.117 2.729-2.831 4.814-2.831 4.814m.425 4.843c-.847-.769-.425-4.843-.425-4.843m-21.526.424c-2.357 9.108-6.534 27.692-4.382 29.165 8.919 4.577 32.081 12.487 47.144-.991 2.973-31.288-35.96-26.616-38.367-14.582m17.131-14.016s-2.124.991-2.69.991"
      ></path>
      <path
        fill="#000"
        d="M151.706 260.131c2.917-2.645 7.928-6.229 7.928-6.229l7.928-3.682 54.648 8.495 4.814 4.814 3.256 5.663 5.663 9.344 5.238 13.449 2.69 11.468 4.531 18.122 1.557 6.795.283 10.76-.425 9.627.567 9.627 1.982 5.805 2.831 4.53 3.115 3.681 1.982 4.106 2.265 4.53 1.274 4.814 1.841 8.07v6.087l-3.54 22.228-7.503 19.537-1.982 2.973-2.407 2.265-3.256-.424-2.407-3.823-3.256-13.025-3.681-16.847-6.513-15.715-13.449-19.254-5.663-9.911-2.973-9.202-10.194-29.448-4.389-10.052-2.831 10.052-6.371 21.803-1.133 5.805v9.344l-.566 8.069-1.982 7.928-4.955 11.61-.425 13.733v14.582l.566 12.034v5.804l-1.698 2.69-2.69 2.124-4.106.708-3.681-2.265-6.088-9.628-4.105-6.654-3.257-6.371-3.681-12.175-.708-5.309-.707-5.309-1.133-7.362v-13.308l.708-6.796-3.823-19.254-3.397-12.6s-1.843-7.034-2.69-11.751c-.733-4.079-.907-6.646-1.416-10.76-.607-4.898-.991-12.6-.991-12.6l1.84-12.742s1.57-7.884 3.54-12.6c1.726-4.133 5.521-10.052 5.521-10.052s4.346-5.065 7.504-7.928z"
      ></path>
      <path
        fill="#fff"
        d="M242.172 439.224l15.857-1.699 3.398.708 1.982 2.407v3.114l-.567 2.407 2.69 5.522 3.398 7.786 1.982 6.654-3.681 7.787-6.937 6.512-7.787 3.681-8.353.991-5.663-.424-9.485-2.407-6.371-3.823.708-7.078 2.548-6.088 3.115-3.823 4.813-3.539 5.947-2.265.991-5.946.849-.425-3.822-1.274-1.416-2.69 1.416-3.681 4.388-2.407z"
      ></path>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="0.849"
        d="M243.022 438.849c1.274 4.339 11.467 9.86 13.874-1.466M245.995 449.701c-1.557.283-4.494-.282-5.38-.558m17.839-.575c1.842-1.101 3.179-2.157 4.105-3.159m-21.944 3.734c-8.753-2.733-2.548-9.353 3.115-10.344l12.458-1.132c7.929-1.133 8.818 5.096 6.371 7.742m-21.944 3.734l-1.132 6.646m23.076-10.38c2.737 5.395 8.24 17.147 8.353 20.998-8.353 23.926-37.234 21.378-48.418 12.175-.907-27.069 34.119-28.74 39.216-16.423M161.616 439.507c3.539 8.636 15.715 7.22 15.148-2.124"
      ></path>
      <path
        fill="#000"
        d="M161.758 439.585H162.607V444.73199999999997H161.758z"
        transform="rotate(-36.285 161.758 439.585)"
      ></path>
      <path
        fill="#000"
        d="M243.163 438.941l13.875-1.275-2.549 4.672-2.265 1.699-3.114.283-3.398-1.982-1.841-1.699-.708-1.698z"
      ></path>
    </svg>
  );
}

export default AlissaToon;
