import React, { useState } from "react";
import styled from "styled-components";
import { Dialog } from "@reach/dialog";
import { breakpoints, fonts } from "../constants";
import Photo from "./Photo";

const Carousel = styled.div`
  display: flex;
  height: 400px;
  width: 100%;
  overflow: auto;
  position: relative;
  gap: 36px;
  @media (max-width: ${breakpoints.xs}) {
    padding: 0px 24px;
  }
`;

const ModalImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 80%;
`;

const StyledDialog = styled(Dialog)`
  background-color: transparent;
  width: fit-content;
  height: 100vh;
  /* display: grid;
  place-items: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0px auto;
  padding: 0px;
`;
const CloseBtn = styled.button`
  padding-right: 16px;
  border: none;
  outline: none;
  font-family: ${fonts.primary};
  background: none;
  color: white;
  font-size: 2rem;
  border-radius: 50%;
  font-weight: bold;
  transition: color 0.2s ease;
  align-self: flex-end;
  cursor: pointer;
  &:hover {
    color: black;
  }
`;

const ProjectPhotos = ({ photos }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [activePhoto, setActivePhoto] = useState("");

  const handleClick = (photo) => {
    setActivePhoto(photo);
    setModalOpen(true);
  };

  return (
    <>
      <Carousel>
        {photos.map((src) => (
          <Photo key={src} src={src} handleClick={handleClick} />
        ))}
      </Carousel>
      <StyledDialog
        aria-label="Image Modal"
        isOpen={modalOpen}
        onDismiss={() => setModalOpen(false)}
      >
        <CloseBtn onClick={() => setModalOpen(false)}>
          close <span style={{ marginLeft: "4px" }}>X</span>
        </CloseBtn>
        <ModalImage src={activePhoto} alt="" />
      </StyledDialog>
    </>
  );
};

export default ProjectPhotos;
