import swaBanner from "../assets/swaBanner.png";
import swaPhone1 from "../assets/swaPhone1.png";
import swaPhone2 from "../assets/swaPhone2.png";
import swaPhone3 from "../assets/swaPhone3.png";
import swaPhone4 from "../assets/swaPhone4.png";
import swaPhone5 from "../assets/swaPhone5.png";
import swaUserPersona from "../assets/swaUserPersona.png";
import swaSiteMap from "../assets/swaSitemap.png";
import swaWireframes from "../assets/swaWireframes.png";
import swaLogoSheet from "../assets/swaLogoSheet.png";
import swaStickerSheet from "../assets/swaStickerSheet.png";
import swaFinalProduct from "../assets/swaFinalProduct.png";

const swaData = {
  general: {
    banner: swaBanner,
    title: "STRONGER WITH ADRIENNE",
    subtitle: "Branding & UX/UI Design",
    description:
      "Stronger with Adrienne is a woman-owned, tiny but mighty, personal training and yoga business based out of Chicago, IL",
    role: [
      "UX/UI Designer",
      "Web Designer",
      "Graphic Designer",
      "Brand Strategist",
    ],
    duration: "12 Weeks",
    tools: ["Figma", "Canva"],
    responsibilities: [
      "Low/high fidelity wireframing",
      "Prototyping",
      "Information Architecture",
      "Accounting for accessibility",
      "Iterating on designs",
      "Responsive Design",
      "Logo Creation",
    ],
    problem:
      "Adrienne’s personal training and yoga business did not yet have an identity, website, or marketing materials. She had little to no way of promoting herself and her small business making it hard for her to gain new clients outside of the gym.",
    goal: "Brand and design a user-centered website that allowed potential clients to better know Adrienne and what services she offers, as well as offer an easy way of communication between her and  current & future clients.",
    live: "https://strongerwithadrienne.com/",
  },
  photos: [swaPhone1, swaPhone2, swaPhone3, swaPhone4, swaPhone5],
  prototype: "",
  accordion: {
    RESEARCH: [
      { title: "PRIMARY RESEARCH" },
      { subtitle: "GENERAL QUESTIONING WITH ADRIENNE" },
      {
        text: "I scheduled a meeting with Adrienne so that she could answer some questions regarding her clientele, target audience, general business branding, and overall focus for this website. We worked together to discover this information:",
        style: { marginBottom: "20px" },
      },
      {
        wrap: [
          {
            text: "Business name:",
            style: {
              fontWeight: 600,
              paddingRight: "4px",
              marginBottom: "0px",
            },
          },
          {
            text: "Stronger With Adrienne",
            style: { marginBottom: "0px" },
          },
        ],
        style: { display: "flex", marginBottom: "24px" },
      },
      {
        wrap: [
          {
            text: "Audience:",
            style: {
              fontWeight: 600,
              paddingRight: "4px",
              marginBottom: "0px",
            },
          },
          {
            text: "Women between ages 25-45",
            style: { marginBottom: "0px" },
          },
        ],
        style: { display: "flex", marginBottom: "24px" },
      },
      {
        wrap: [
          {
            text: "Services Offered:",
            style: {
              fontWeight: 600,
              paddingRight: "4px",
              marginBottom: "0px",
            },
          },
          {
            text: "Strength training and/or Yoga sessions",
            style: { marginBottom: "0px" },
          },
        ],
        style: { display: "flex", marginBottom: "24px" },
      },
      {
        wrap: [
          {
            text: "Primary way to communicate with current and future clients:",
            style: {
              fontWeight: 600,
              paddingRight: "4px",
              marginBottom: "0px",
            },
          },
          {
            text: "Business email",
            style: { marginBottom: "0px" },
          },
        ],
        style: { display: "flex", marginBottom: "24px" },
      },
      {
        wrap: [
          {
            text: "Current branding:",
            style: {
              fontWeight: 600,
              paddingRight: "4px",
              marginBottom: "0px",
            },
          },
          {
            text: "None",
            style: { marginBottom: "0px" },
          },
        ],
        style: { display: "flex", marginBottom: "24px" },
      },
      { subtitle: "INTERVIEWS" },
      {
        text: "I conducted 3 interviews, each lasting approximately 15 minutes long. I decided to only interview women, or those who identified as female and that were between the ages of 25-45 years old. The goal of interviewing was to uncover the user pain points, motivators, and general feelings about their workout routine and/or their gym-going and personal training/yoga experiences. I also wanted to uncover user preferences regarding what information is most frequently saught out specifically when searching for a personal trainer or yoga instructor.",
      },
      { subtitle: "MOTIVATORS" },
      {
        ul: [
          "All users mentioned that they google search when looking for a gym, yoga instructor or trainer.",
          "Most users search local gyms to find their instructors.",
          "Quality design (easily accessible information, aesthetics, navigation, flow) improves user perception and experience.",
          "It is important to many users that they are offered several ways get in touch with the gym, trainers, or instructors (email, phone, chat box).",
          "Users like several payment method options.",
          "Users appreciate when an instructor/trainer mentions some information about themselves, to get an idea if they’d be a good fit.",
        ],
      },
      { subtitle: "PAINS" },
      {
        ul: [
          "Difficulty finding women trainers",
          "Contact button or page is not always easily identifiable.",
          "Inability to see services offered.",
          "Struggle to find important information, such as an “about” section.",
          "Cluttered, cold, and/or frustrating website layouts.",
          "Lack of communication strategies.",
        ],
      },
      { title: "USER PERSONA" },
      { image: { src: swaUserPersona, alt: "" } },
      {
        text: "Sarah represents a primary group of users. To keep the target users’ goals, pain points, and priorities in mind while moving forward in the design process, I created this user persona.",
      },
    ],
    ["ARCHITECTURE & INTERACTION"]: [
      { title: "SITE MAP" },
      { image: { src: swaSiteMap, alt: "" } },
      {
        text: "This site map is a visual overview of the Stronger with Adrienne website. To avoid user frustration, I focused on the information architecture early on in the process to determine what information needed to be included and how to prioritize that information on each page.",
      },
      {
        text: "Depending on the user’s goals, they have a few call-to-action’s that they’re able to choose from. The navigation bar is sticky, making it easy to contact Adrienne at any time during the browsing experience. The user is able to travel between the home, about, strength, yoga, and services pages quickly and simply. Because this website’s information is concise, I stuck to a single page design, making it fully scrollable. This means that users do not have to travel to pages by the navigation bar at all, instead they are able to simply scroll down to see all the information provided. The overall IA of this design is simple and to-the-point.",
      },
    ],
    WIREFRAMING: [
      { title: "WIREFRAMING" },
      { image: { src: swaWireframes, alt: "" } },
      {
        text: "I started the wireframing process by creating two low fidelity website and web application options. I decided to keep the website one page with navigation that anchors to it’s appropriate page to keep things simple in both options. Both are similar in general layout, however the first option offered more artistic flare, while option 2 was more straight-forward and reminiscent of current gym website trends.  Option 1 leaned more traditionally feminine, while option 2 feels more modern and trendy. The user flow of this website considers the aspects that are important to it’s users: an intuitive flow, clear navigation, a simple, uncrowded layout, and a simple way to get the information they want about Adrienne and her services.",
      },
    ],
    ["UI & BRANDING"]: [
      { title: "UI & BRANDING" },
      { subtitle: "LOGO DESIGN" },
      { image: { src: swaLogoSheet, alt: "" } },
      {
        text: "Adrienne wanted her logo to be simple, effective, and stylish. She wanted to portray that she is not only a weight lifting coach, but also a yoga instructor. I was asked to design a logo that found a balance between traditionally “masculine” and traditionally “feminine” while also maintaining uniqueness. We settled on the the light and dark versions above that portray a lotus flower to represent her yoga instructor side and a bit of femininity, alongside a bent barbell with weights to represent her strength training and more masculine side.",
      },
      { subtitle: "TYPOGRAPHY & COLOR" },
      { image: { src: swaStickerSheet, alt: "" } },
      {
        text: "Once we agreed on the color palette and overall tone of the website, we decided to go with a classic gym-like font to tie it all together. I chose a decorative font, SF Collegiate, for the headers and a sans-serif font, Lato, for the CTA’s and body text. Both of these fonts deem simple readability while also maintaining the integrity of the *vibe*.",
      },
    ],
    ["FINAL PRODUCT"]: [
      { title: "STRONGER WITH ADRIENNE" },
      {
        image: { src: swaFinalProduct, alt: "" },
      },
      {
        text: "The Stronger with Adrienne website and web app’s main focus is to increase her clientele by making it simple and efficient for her to inform her potential clients of her background and the services she offers. There are only a handful of CTA’s for the user to choose from, making it both easy to get in contact with Adrienne and  see where the gym she trains at is located in Chicago. The one-page application anchors each CTA in the navigation to it’s appropriate section within the design, this results in a smooth animated transition and eliminates opening multiple pages and slow loading times.",
      },
      {
        text: "To see the full application:",
        style: { alignSelf: "center", marginBottom: "32px" },
      },
      {
        buttonLink: {
          text: "GO TO WEBSITE",
          link: "https://strongerwithadrienne.com/",
        },
        style: { alignSelf: "center", marginBottom: "64px" },
      },
    ],
  },
  finalText:
    "Stronger with Adrienne was my first freelance client and this experience taught me how to work directly with a stakeholder. My design and creative skills were tested during this project.",
};

export default swaData;
