import styled, { ThemeProvider } from "styled-components";
import { createGlobalStyle } from "styled-components";
import { colors, fonts, linePosition } from "./constants";
import marsh from "./fonts/Marshland_Beauty.otf";
import montserrat from "./fonts/Montserrat-Medium.ttf";
import montserratBold from "./fonts/Montserrat-Bold.ttf";
import Main from "./Main";
import Project from "./layout/project/Project.js";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import React from "react";
import ScrollToTop from "./components/ScrollToTop";
import theatrSeatrData from "./data/theatrSeatrData";
import evergreenData from "./data/evergreenData";
import columbiaData from "./data/columbiaData";
import Navbar from "./components/Navbar";
import swaData from "./data/swaData";

const theme = {
  colors,
  fonts,
  linePosition,
};

function App() {
  return (
    <main>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <ScrollToTop>
            <Routes>
              <Route path="/">
                <Route index element={<Main />} />
                <Route
                  path="project/theatrSeatr"
                  element={<Project projectData={theatrSeatrData} />}
                />
                <Route
                  path="project/evergreen"
                  element={<Project projectData={evergreenData} />}
                />
                <Route
                  path="project/columbia"
                  element={<Project projectData={columbiaData} />}
                />
                <Route
                  path="project/swa"
                  element={<Project projectData={swaData} />}
                />
              </Route>
            </Routes>
          </ScrollToTop>
        </ThemeProvider>
      </BrowserRouter>
    </main>
  );
}
const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
  /*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
  background-color: ${colors.bg};
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}
/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}
// -------------------------------------------

@font-face {
  font-family: Marsh;
  src: url(${marsh});
}
@font-face {
  font-family: Montserrat;
  src: url(${montserrat});
}
@font-face {
  font-family: MontserratBold;
  src: url(${montserratBold});
}

`;

export default App;
