import React from "react";
import styled from "styled-components";

const Wrapper = styled.section`
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.bg};
  position: relative;
  @media (max-width: 900px) {
    height: auto;
  }
`;
// const InnerWrap = styled.div`
//   margin: 0px auto;
//   height: 100%;
//   width: 100%;
//   max-width: 1440px;
// `;

const Page = ({ className, children, id, delayLines }) => {
  return (
    <Wrapper className={className} id={id}>
      {children}
    </Wrapper>
  );
};

export default Page;
