import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../constants";

const InnerWrap = styled.div`
  border: 6px solid ${colors.primaryHighlight};
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const ImgWrap = styled.div`
  display: grid;
  place-items: center;
  height: 80%;
  position: relative;
`;
const Image = styled.img`
  display: block;
`;
const Title = styled.p`
  background-color: ${colors.primaryHighlight};
  height: 20%;
  display: grid;
  place-items: center;
  font-family: ${fonts.secondary};
  font-size: 1.25rem;
  color: ${colors.secondary};
`;
const HoverImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: opacity 0.3s ease;
`;
const Wrapper = styled.a`
  display: block;
  background-color: ${colors.secondary};
  border-radius: 20px;
  border: 4px solid ${colors.secondary};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 340px;
  height: 340px;
  cursor: pointer;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
  &:hover ${HoverImage} {
    opacity: 1;
  }
`;

const CoolProject = ({ img, imgStyle, title, hoverImg }) => {
  return (
    <Wrapper>
      <InnerWrap>
        <ImgWrap>
          <HoverImage src={hoverImg} alt="" />
          <Image style={imgStyle} src={img} alt="" />
        </ImgWrap>

        <Title>{title}</Title>
      </InnerWrap>
    </Wrapper>
  );
};

export default CoolProject;
