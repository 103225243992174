import React from "react";
import { HashLink } from "react-router-hash-link";
import { animated } from "react-spring";
import styled from "styled-components";
import backPointer from "../assets/backPointer.png";
import { breakpoints, colors, fonts } from "../constants";
import useBoop from "../hooks/useBoop";

const Wrapper = styled.div`
  cursor: pointer;
  position: fixed;
  top: 48px;
  left: 48px;
  @media (max-width: ${breakpoints.md}) {
    top: 24px;
    left: 24px;
  }
`;
const StyledLink = styled(HashLink)`
  display: flex;
  align-items: flex-end;
  color: black;
  text-decoration: none;
`;
const Booper = styled(animated.div)``;
const Image = styled.img`
  width: 80px;
  height: auto;
  @media (max-width: ${breakpoints.md}) {
    width: 60px;
  }
  @media (max-width: ${breakpoints.sm}) {
    width: 48px;
  }
`;
const Label = styled.p`
  will-change: transform;
  font-family: ${fonts.primary};
  font-size: 1.4rem;
  font-weight: 600;
  transition: color 0.2s ease;
  padding-bottom: 6px;
  padding-left: 4px;

  @media (max-width: ${breakpoints.md}) {
    font-size: 1.2rem;
    transform: translateY(4px);
  }
  @media (max-width: ${breakpoints.sm}) {
    opacity: 0;
  }
`;

const BackPointer = () => {
  const [style, trigger] = useBoop({
    x: -12,
    y: 0,
    rotation: 0,
    scale: 1.1,
    springConfig: { tension: 400, friction: 10 },
  });
  return (
    <Wrapper onMouseEnter={trigger}>
      <StyledLink to="/#work">
        <Booper style={style}>
          <Image src={backPointer} alt="" />
        </Booper>
      </StyledLink>
    </Wrapper>
  );
};

export default BackPointer;
