import React from "react";
import { colors, fonts } from "../constants";
import styled from "styled-components";

const Wrapper = styled.button`
  font-family: ${fonts.secondaryBold};
  cursor: pointer;
  border-radius: 32px;
  border: 2px solid
    ${(props) => (props.border ? props.border : colors.secondary)};
  padding: 12px 36px;
  background-color: ${(props) =>
    props.bg ? props.bg : colors.primaryHighlight};
  color: ${(props) => (props.color ? props.color : colors.secondary)};
  transition: background-color 0.2s ease, color 0.2s ease;
  &:hover {
    color: ${(props) => (props.bg ? props.bg : colors.primaryHighlight)};
    background-color: ${(props) =>
      props.hover ? props.hover : colors.secondary};
  }
`;

const CoolBtn = ({
  color,
  border,
  bg,
  hover,
  children,
  onClick,
  className,
}) => {
  return (
    <Wrapper
      onClick={onClick}
      color={color}
      border={border}
      bg={bg}
      hover={hover}
      className={className}
    >
      {children}
    </Wrapper>
  );
};

export default CoolBtn;
