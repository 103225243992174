import acadia from "./assets/photography/acadia.jpg";
import aliyah from "./assets/photography/aliyah.jpg";
import cabin from "./assets/photography/cabin.jpg";
import cafe from "./assets/photography/cafe.jpg";
import cafePortugal from "./assets/photography/cafePortugal.jpg";
import chicago from "./assets/photography/chicago.jpg";
import church from "./assets/photography/church.jpg";
import coronado from "./assets/photography/coronado.jpg";
import cowboy from "./assets/photography/cowboy.jpg";
import fallTrees from "./assets/photography/fallTrees.jpg";
import ferris from "./assets/photography/ferris.jpg";
import flowers from "./assets/photography/flowers.jpg";
import grayMountains from "./assets/photography/grayMountains.jpg";
import icelandBoats from "./assets/photography/icelandBoats.jpg";
import koiaPorch from "./assets/photography/koiaPorch.jpg";
import lake from "./assets/photography/lake.jpg";
import leafBug from "./assets/photography/leafBug.jpg";
import lighthouse from "./assets/photography/lighthouse.jpg";
import mistyShip from "./assets/photography/mistyShip.jpg";
import palace from "./assets/photography/palace.jpg";
import palm from "./assets/photography/palm.jpg";
import pink from "./assets/photography/pink.jpg";
import seattleShips from "./assets/photography/seattleShips.jpg";
import sunset from "./assets/photography/sunset.jpg";
import tunnel from "./assets/photography/tunnel.jpg";
import underbridge from "./assets/photography/underbridge.jpg";
import whiteFlower from "./assets/photography/whiteFlower.jpg";

const photos = [
  icelandBoats,
  underbridge,
  cafePortugal,
  cafe,
  church,
  aliyah,
  acadia,
  mistyShip,
  coronado,
  grayMountains,
  fallTrees,
  cabin,
  pink,
  flowers,
  tunnel,
  palace,
  koiaPorch,
  ferris,
  lake,
  leafBug,
  lighthouse,
  chicago,
  palm,
  seattleShips,
  cowboy,
  sunset,
  whiteFlower,
];
export default photos;
